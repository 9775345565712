import React, { Component } from 'react'
import {connect} from "react-redux";
import Auth from '@aws-amplify/auth';
import { Collapse} from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RecipeCard from '../../components/RecipeCard';
import {getAllRecipes} from "../../services/recipe"
import {setAuthorizationToken} from '../../store/actions/auth';
import {addNotification} from "../../store/actions/notifications";

class AllRecipes extends Component {
    constructor(props) {
        super(props)
        this.state= {
            allRecipes:[],
            filterOpen:false,
            filterOptions: {
                meal:['Breakfast','Lunch','Dinner','Snack']
            },
            filters: {
                meal:[],
                text:''
            },
            filteredRecipes: []
        }
        this.toggleFilters = this.toggleFilters.bind(this);
    }

    async componentDidMount() {
        if (this.props.currentUser.isAuthenticated) {
            this.getData()
        } else {
            Auth.currentAuthenticatedUser()
                .then(async user => {
                    const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                    setAuthorizationToken(jwt);
                    this.getData()
                })
                .catch(err => {});
        }
    }

    getData() {
        this.props.getAllRecipes()
            .then(data => {
                this.setState({
                    allRecipes: data.allRecipes,
                    filteredRecipes:data.allRecipes
                });
            })
    }

    toggleFilters = e => {
        e.preventDefault()
        this.setState({
            filterOpen: !this.state.filterOpen
        })
    }

    handleFilter = e => {
        let {filters, filterOptions, allRecipes} = this.state;

        // Update if a meal filter
        if (filterOptions.meal.includes(e.target.name)) {
            if (filters.meal.includes(e.target.value)) {
                filters.meal = filters.meal.filter(m => m !== e.target.value);
            } else {
                filters.meal.push(e.target.value)
            }
        }

        if (e.target.name==='text') {
            filters.text = e.target.value
        }

        let filteredRecipes = allRecipes.filter(r => {
            return (filters.meal.length ? filters.meal.some(m => r.meal.includes(m)): true) &&
                (filters.text.length ? r.name.includes(filters.text): true)
        })

        this.setState({
            filters,
            filteredRecipes
        })
        
    }

    render() {
        let recipes = this.state.filteredRecipes.map(r => (
            <RecipeCard key={r.id} recipe={r} />
        ));

        let mealOptions = this.state.filterOptions.meal.map(m => (
            <div key={m} className="custom-control custom-checkbox">
                <input
                    className="custom-control-input"
                    type="checkbox"
                    id={m}
                    name={m}
                    value={m.toLowerCase()}
                    onChange={this.handleFilter}
                    checked={this.state.filters.meal.includes(m.toLowerCase())} />
                < label className="custom-control-label"
                    htmlFor={m} >{m}</ label>
            </div>
        ));

        return (
            <div className="mt-2 mt-md-5">
                <h2 className="text-center">All Recipes</h2>
                <div className="w-100 text-center mt-2 mt-md-4">
                    <button 
                        onClick={this.toggleFilters} 
                        className="btn btn-primary btn-lg bg-brand-blue-gradient mx-auto"
                    >
                        <FontAwesomeIcon icon={['fas', 'filter']}/> Filters
                    </button>
                    <Collapse isOpen={this.state.filterOpen} className="w-100">
                        <hr />
                        <div className="row justify-content-around mt-2 mt-md-4">
                            <div className="form-group col-md-4">
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text">
                                            <FontAwesomeIcon icon={['fas', 'search']} />
                                        </span>
                                    </div>
                                    <input
                                        type="text"
                                        className="form-control text-center"
                                        name="text"
                                        placeholder="Recipe Title"
                                        value={this.state.filters.text}
                                        onChange={this.handleFilter}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="d-flex justify-content-around mt-2">
                            {mealOptions}
                        </div>
                        <hr />
                    </Collapse>
                </div>
                <div className="row justify-content-around mt-2 mt-md-4">
                    {recipes}
                </div>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser
    };
}

export default connect(
    mapStateToProps, {
        addNotification,
        getAllRecipes
    })(AllRecipes);