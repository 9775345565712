import React from "react";
import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import SignIn from "./SignIn";
import SignUp from "./SignUp";
import PasswordReset from "./PasswordReset";


const AuthRoutes = props => {
    return (
        <Switch>
            <Route
                exact
                path="/auth/signin"
                render={(props) => <SignIn {...props} />}
            />
            <Route
                exact
                path="/auth/signup"
                render={(props) => <SignUp {...props} />}
            />
            <Route
                exact
                path="/auth/password-reset"
                render={(props) => <PasswordReset {...props} />}
            />
        </Switch>
    )
}


export default withRouter(AuthRoutes);