import React, { Component } from 'react'
import { connect } from "react-redux";
import Auth from '@aws-amplify/auth';
import {
    TabContent, TabPane, Nav, NavItem, NavLink
} from 'reactstrap';
import {getRecipe} from "../../services/recipe"
import { setAuthorizationToken } from '../../store/actions/auth';
import { addNotification } from "../../store/actions/notifications";

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser
    };
}

class ViewRecipe extends Component {
    constructor(props) {
        super(props) 
        this.state = {
            recipe: {
                name: '',
                duration: '',
                serves: '',
                ingredients: [],
                instuctions: [],
                meal: [],
                calsPerPerson: 0,
                proteinPerPerson: 0
            },
            activeTab: 'ingredients'
        }
        this.selectTab = this.selectTab.bind(this);
    
    }

    async componentDidMount() {
        let {
            recipeId
        } = this.props.match.params;

        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                setAuthorizationToken(jwt);

                this.props.getRecipe(recipeId)
                    .then(data => {
                        this.setState({
                            recipe: data
                        })
                    })
            })
            .catch(err => { });
    }

    selectTab = e => {
        this.setState({
            activeTab: e.target.name
        })
    }

    render() {
        let meals = this.state.recipe.meal.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ');

        let ingredients = this.state.recipe.ingredients.map((i) => {
            return (
                <li className="list-group-item" key={i.id}>{`${i.name} - ${i.quantity} ${i.unit}`}</li>
            )
        });

        let method = this.state.recipe.instuctions.map((i) => (
            <li className="mb-3" key={i}>{i}</li>
        ));

        let { activeTab } = this.state
        return (
            <div className="mt-md-5 mt-2">
                < h2 className="text-center"> {this.state.recipe.name} </h2>
                <p className="text-center">{meals}</p>
                <hr />
                <div className="form-group mt-md-5 mt-2 row d-flex h-100 ">
                    <div className="col-md-6 d-flex h-100">
                        < div className="form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="duration"
                                className="col-md-6 text-center text-md-left align-self-center mb-0" >
                                Cooking Time (mins)</label>
                            < div className="input-group col-md-6" >
                                <input
                                    type='text'
                                    value={this.state.recipe.duration}
                                    name='duration'
                                    id="duration"
                                    placeholder='e.g. 30'
                                    className="form-control text-center" 
                                    disabled={true}/>
                            </ div>
                        </div>
                    </div>
                    < div className="col-md-6 d-flex h-100" >
                        < div className="form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="served"
                                className="col-md-6 text-center text-md-left align-self-center mb-0" >
                                Serves</label>
                            < div className="input-group col-md-6" >
                                <input
                                    type='text'
                                    value={this.state.recipe.serves}
                                    name='serves'
                                    id="served"
                                    placeholder='e.g. 4'
                                    className="form-control text-center" 
                                    disabled={true}/>
                            </ div>
                        </div>
                    </div>
                </div>
                <div className="form-group mt-md-5 mt-2 row d-flex h-100 ">
                    <div className="col-md-6 d-flex h-100">
                        < div className="form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="calsPerPerson"
                                className="col-md-6 text-center text-md-left align-self-center mb-0" >
                                Calories per Person</label>
                            < div className="input-group col-md-6" >
                                <input
                                    type='text'
                                    value={this.state.recipe.calsPerPerson || 0}
                                    name='calsPerPerson'
                                    id="calsPerPerson"
                                    className="form-control text-center"
                                    disabled={true} />
                                <div className="input-group-append">
                                    < span className="input-group-text" > kCal </span>
                                </div>
                            </ div>
                        </div>
                    </div>
                    < div className="col-md-6 d-flex h-100" >
                        < div className="form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="proteinPerPerson"
                                className="col-md-6 text-center text-md-left align-self-center mb-0" >
                                Protein per Person</label>
                            < div className="input-group col-md-6" >
                                < input
                                    type='text'
                                    value={this.state.recipe.proteinPerPerson || 0}
                                    name='proteinPerPerson'
                                    id="proteinPerPerson"
                                    className="form-control text-center"
                                    disabled={true} />
                                <div className="input-group-append">
                                    < span className="input-group-text" > g </span>
                                </div>
                            </ div>
                        </div>
                    </div>
                </div>
                <hr />
                < Nav tabs className="mt-md-5 mt-2 nav-justified" >
                    <NavItem>
                        <NavLink
                            className={activeTab === 'ingredients' && 'active'}
                            onClick={this.selectTab}
                            name="ingredients"
                        >
                            Ingredients
                                </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === 'method' && 'active'}
                            onClick={this.selectTab}
                            name="method"
                        >
                            Method
                                </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                    <TabPane tabId="ingredients">
                        <ul className="list-group mt-2">
                            {ingredients}
                        </ul>
                    </TabPane>
                    <TabPane tabId="method">
                        <ol className="list-group-numbered mt-2">
                            {method}
                        </ol>
                    </TabPane>
                </TabContent>
            </div>
        )
    }
}

export default connect(
    mapStateToProps, {
    getRecipe,
    addNotification
})(ViewRecipe);