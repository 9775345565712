import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const IngredientModal = (props) => {
    const {
        open,
        toggle,
        className,
        ingredient,
        handleInput,
        submitForm,
    } = props;

    return (
        <div>
            <Modal isOpen={open} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Add New Ingredient</ModalHeader>
                <ModalBody>
                    {/* Name, Category, Unit */}
                    < div className="form-group row d-flex h-100 pr-3" >
                        <label
                            htmlFor="name"
                            className="col-md-3 text-center text-md-left align-self-center mb-0" >
                            Name</label>
                        <input
                            type='text'
                            value={ingredient.name}
                            onChange={handleInput}
                            name='name'
                            id="name"
                            placeholder='e.g. Steak'
                            className=" col-md-9 form-control" />
                    </div>
                    < div className="form-group row d-flex h-100 pr-3" >
                        <label
                            htmlFor="category"
                            className="col-md-3 text-center text-md-left align-self-center mb-0" >
                            Category</label>
                        <select
                            type='text'
                            value={ingredient.category}
                            onChange={handleInput}
                            name='category'
                            id="category"
                            className=" col-md-9 form-control" >
                            <option value="">Please Select...</option>
                            < option value="meatFish">Meat and Fish</option>
                            < option value = "fruitVeg" >Fruit and Veg</option>
                            < option value = "deli" >Deli</option>
                            < option value = "cereal" >Cereal</option>
                            < option value = "dairy" >Dairy</option>
                            < option value = "bakery" >Bakery</option>
                            < option value = "other" >Other</option>
                        </select>
                    </div>
                    < div className="form-group row d-flex h-100 pr-3" >
                        <label
                            htmlFor="unit"
                            className="col-md-3 text-center text-md-left align-self-center mb-0" >
                            Unit</label>
                        <input
                            type='text'
                            value={ingredient.unit}
                            onChange={handleInput}
                            name='unit'
                            id="unit"
                            placeholder='e.g. g'
                            className=" col-md-9 form-control" />
                    </div>
                    < div className="form-group row d-flex h-100 pr-3" >
                        <label
                            htmlFor="unit"
                            className="col-md-3 text-center text-md-left align-self-center mb-0" >
                            Calories</label>
                        <input
                            type='text'
                            value={ingredient.cals}
                            onChange={handleInput}
                            name='cals'
                            id="cals"
                            placeholder='enter number of calories per unit above'
                            className=" col-md-9 form-control" />
                    </div>
                    < div className="form-group row d-flex h-100 pr-3" >
                        <label
                            htmlFor="unit"
                            className="col-md-3 text-center text-md-left align-self-center mb-0" >
                            Protein</label>
                        <input
                            type='text'
                            value={ingredient.protein}
                            onChange={handleInput}
                            name='protein'
                            id="protein"
                            placeholder = 'enter grams of protein per unit above'
                            className=" col-md-9 form-control" />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submitForm}>Add</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}
 
export default IngredientModal