import React from 'react';
import {
    ResponsiveContainer,
    LineChart,
    Line,
    ReferenceLine,
    CartesianGrid,
    XAxis,
    YAxis
} from 'recharts';
import moment from 'moment';

const TrackingChart = (props) => {
    const {
        data,
        target
    } = props

    let date_min = 0
    let date_max = 0
    let weight_min = 0
    let weight_max = 0

    if (data.length > 0) {
        date_min = data[0]['date'] - (1000 * 60 * 60 * 24)
        date_max = data.slice(-1)[0]['date'] + (1000 * 60 * 60 * 24);

        weight_max = Math.max(...data.map(o=>o.weight));
        weight_min = Math.min(...data.map(o=>o.weight));
    }

    if (target!==null) {
        if (target > weight_max) {
            weight_max = target;
        }

        if (target < weight_min) {
            weight_min = target
        }
    }

    if (weight_max%5===0) {
        weight_max = weight_max + 5
    } else {
        weight_max = Math.ceil(weight_max / 5) * 5;
    }

    if (weight_min % 5 === 0) {
        weight_min = weight_min - 5
    } else {
        weight_min = Math.floor(weight_min / 5) * 5;
    }

    const dateFormatter = date => {
        // return moment(date).unix();
        return moment(date).format('DD-MMM-YY');
    };

    return (
        <ResponsiveContainer 
            width="100%" 
            height={300} 
            data={data}
            key={`rc_${data.length}`}>
            <LineChart data={data} key={`lc_${data.length}`}>
                <Line dataKey="weight" stroke="#8884d8" key={`l_${data.length}`}/>
                {target >0  && (
                    <ReferenceLine 
                        y={target} 
                        stroke="green" 
                        strokeDasharray="3 3"
                        // label="Target Weight" 
                    />
                )}                
                <CartesianGrid stroke="#ccc" />
                <XAxis
                    dataKey="date"
                    scale="time"
                    type="number"
                    domain={[date_min, date_max]}
                    tickFormatter={dateFormatter} />
                <YAxis 
                    type="number"
                    domain={[weight_min, weight_max]}
                    interval="preserveStartEnd"/>
            </LineChart>
        </ResponsiveContainer>
    )
}

export default TrackingChart