import React, { Component } from "react";
import { connect } from "react-redux";
import Auth from '@aws-amplify/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";
import { setAuthorizationToken } from "../../store/actions/auth";
// import moment from 'moment';

class MyAccountHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            family_name: '',
            given_name:'',
            oldPassword:'',
            newPassword:'',
            confirmNewPassword:'',
            passwordsMatch: true,
            passwordLength: false,
            passwordLowerCase: false,
            passwordNumbers: false,
            passwordUpperCase: false,
            canChangePassword: false,
        }
        this.handleAttrInput = this.handleAttrInput.bind(this);
        this.updateAttrs = this.updateAttrs.bind(this);
        this.handlePwdInput = this.handlePwdInput.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.canChangePwd = this.canChangePwd.bind(this);
        this.changePwd = this.changePwd.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                //console.log(jwt);
                setAuthorizationToken(jwt);
                this.setState({
                    email: user.attributes.email,
                    family_name: user.attributes.family_name,
                    given_name: user.attributes.given_name
                })
            })
            .catch(err => { });
    }

    handleAttrInput = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    updateAttrs = async e => {
        e.preventDefault();
        let {
            family_name,
            given_name,
            email
        } = this.state;

        try {
            let user = await Auth.currentAuthenticatedUser();
            let result = await Auth.updateUserAttributes(
                user,
                {   
                    email,
                    family_name,
                    given_name
                }
            )
            console.log(result)
        } catch (error) {
            
        }
    }

    handlePwdInput = e => {
        let {newPassword,
            confirmNewPassword,
            passwordsMatch } = this.state;

        if (e.target.name === 'confirmNewPassword') {
            if (e.target.value === newPassword) {
                passwordsMatch = true
            } else {
                passwordsMatch = false
            }
        }

        if (e.target.name === 'newPassword') {
            if (e.target.value === confirmNewPassword) {
                passwordsMatch = true
            } else {
                passwordsMatch = false
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            passwordsMatch: passwordsMatch
        }, () => {
            this.validatePassword()
        })
    }

    validatePassword = () => {
        let passwordLength = false;
        let passwordLowerCase = false;
        let passwordNumbers = false;
        let passwordUpperCase = false;

        let { newPassword } = this.state;

        if (newPassword.match(/[A-Z]/g)) {
            passwordUpperCase = true
        }
        if (newPassword.match(/[a-z]/g)) {
            passwordLowerCase = true
        }
        if (newPassword.match(/[0-9]/g)) {
            passwordNumbers = true
        }
        if (newPassword.length >= 8) {
            passwordLength = true
        }

        this.setState({
            passwordLength,
            passwordLowerCase,
            passwordUpperCase,
            passwordNumbers,
        }, () => {
            this.canChangePwd();
        })
    }

    canChangePwd = () => {
        let {
            passwordLength,
            passwordLowerCase,
            passwordUpperCase,
            passwordNumbers,
            passwordsMatch,
        } = this.state;

        let canChangePassword = false;

        if (
            passwordLength &&
            passwordLowerCase &&
            passwordUpperCase &&
            passwordNumbers &&
            passwordsMatch
        ) { canChangePassword = true }

        this.setState({ canChangePassword })
    }

    changePwd = async e => {
        e.preventDefault()

        let {
            oldPassword,
            newPassword,
        } = this.state;

        try {
            let user = await Auth.currentAuthenticatedUser();
            let result = await Auth.changePassword(user, oldPassword, newPassword)
            console.log(result);
            this.setState({
                oldPassword: '',
                newPassword: '',
                confirmNewPassword: '',
                passwordsMatch: true,
                passwordLength: false,
                passwordLowerCase: false,
                passwordNumbers: false,
                passwordUpperCase: false,
                canChangePassword: false,
            })
        } catch (error) {

        }
        
    }

    render() {
        return (
            <div className="text-center">
                <h2>My Profile</h2>
                <hr className="mt-md-5 mt-2" />
                <h4 className="text-left">Account Details</h4>
                < div className="mt-md-4 mt-2 form-group row" >
                    <label
                        htmlFor="email"
                        className="col-md-3 text-center text-md-right align-self-center mb-0" >
                        e-Mail/username</label>
                    <input
                        type='text'
                        value={this.state.email}
                        name='email'
                        id="email"
                        className=" col-md-9 form-control text-center"
                        disabled />
                </div>
                <div className="form-group row">
                    <div className="col-md-6">
                        < div className="form-group row" >
                            <label
                                htmlFor="given_name"
                                className="col-md-6 text-center text-md-right align-self-center mb-0" >
                                First Name</label>
                            <input
                                type='text'
                                value={this.state.given_name}
                                onChange = {this.handleAttrInput}
                                name='given_name'
                                id="given_name"
                                className=" col-md-6 form-control text-center" 
                                disabled/>
                        </div>
                    </div>
                    <div className="col-md-6">
                        < div className="form-group row" >
                            <label
                                htmlFor="family_name"
                                className="col-md-6 text-center text-md-right align-self-center mb-0" >
                                Last Name</label>
                            <input
                                type='text'
                                value={this.state.family_name}
                                onChange={this.handleAttrInput}
                                name='family_name'
                                id="family_name"
                                className=" col-md-6 form-control text-center" 
                                disabled/>
                        </div>
                    </div>
                </div>
                {/* <button
                    className="btn btn-primary btn-lg bg-brand-blue-gradient mx-auto"
                    onClick = {this.updateAttrs}
                >
                    Update Profile
                </button> */}
                <hr className="mt-md-5 mt-2" />
                <h4 className="text-left">Change your Password</h4>
                < div className="form-group row" >
                    <label
                        htmlFor="oldPassword"
                        className="col-sm-3 text-center text-md-right align-self-center mb-0" >
                        Old password</label>
                    <input
                        type='password'
                        value={this.state.oldPassword}
                        onChange={this.handlePwdInput}
                        name='oldPassword'
                        id="oldPassword"
                        className="col-sm-6 form-control text-center"/>
                </div>

                < div className="form-group row" >
                    <label
                        htmlFor="newPassword"
                        className="col-sm-3 text-center text-md-right align-self-center mb-0" >
                        New password</label>
                    <input
                        type='password'
                        value={this.state.newPassword}
                        onChange={this.handlePwdInput}
                        name='newPassword'
                        id="newPassword"
                        className=" col-sm-6 form-control text-center" />
                </div>

                < div className="form-group row" >
                    <label
                        htmlFor="confirmNewPassword"
                        className="col-sm-3 text-center text-md-right align-self-center mb-0" >
                        Confirm new password</label>
                    <input
                        type='password'
                        value={this.state.confirmNewPassword}
                        onChange={this.handlePwdInput}
                        name='confirmNewPassword'
                        id="confirmNewPassword"
                        className=" col-sm-6 form-control text-center" />
                </div>
                <div id="password_val" className="text-left">
                    <p>Password must contain the following:</p>
                    <ul className="fa-ul">
                        <li id="letter">
                            {this.state.passwordLowerCase ?
                                <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true} /> :
                                <FontAwesomeIcon icon={['far', 'circle']} listItem={true} />
                            } A <b>lowercase</b> letter
                                </li>
                        <li id="capital">
                            {this.state.passwordUpperCase ?
                                <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true} /> :
                                <FontAwesomeIcon icon={['far', 'circle']} listItem={true} />
                            }A <b>capital (uppercase)</b> letter
                                </li>
                        <li id="number">
                            {this.state.passwordNumbers ?
                                <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true} /> :
                                <FontAwesomeIcon icon={['far', 'circle']} listItem={true} />
                            }A <b>number</b>
                        </li>
                        <li id="length">
                            {this.state.passwordLength ?
                                <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true} /> :
                                <FontAwesomeIcon icon={['far', 'circle']} listItem={true} />
                            }Minimum <b>8 characters</b>
                        </li>
                    </ul>
                </div>
                <button
                    className="btn btn-primary btn-lg bg-brand-blue-gradient mx-auto"
                    onClick = {this.changePwd}
                    disabled={!this.state.canChangePassword}
                >
                    Change Password
                </button>
            </div>
            
        )
    }

}

// function mapStateToProps(state) {
//     return {
//         currentUser: state.currentUser
//     };
// }

export default connect(
    null,
    {
        setAuthorizationToken
    }
)(MyAccountHome)