import React, { Component } from "react";
import { connect } from "react-redux";
import Auth from '@aws-amplify/auth';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setAuthorizationToken } from "../../store/actions/auth";

class CalorieCalculator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            height: '',
            weight:'',
            age:'',
            gender:'',
            weeklyActivity:'',
            bmr:0,
            totalExpenditure:0,
            weightLoss:0
        }
        this.handleChange = this.handleChange.bind(this);
        this.calcCalories = this.calcCalories.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                //console.log(jwt);
                setAuthorizationToken(jwt);
            })
            .catch(err => { });
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        }, () => {
            this.calcCalories();
        })
    }

    calcCalories = e =>{
        // Harris-Benedict equation
        // For men: BMR = 10 x weight(kg) + 6.25 x height(cm) – 5 x age(years) + 5
        // For women: BMR = 10 x weight(kg) + 6.25 x height(cm) – 5 x age(years) – 161

        // Sedentary(little or no exercise) : Calorie - Calculation = BMR x 1.2
        // Lightly active(light exercise / sports 1 - 3 days / week) : Calorie - Calculation = BMR x 1.375
        // Moderately active(moderate exercise / sports 3 - 5 days / week) : Calorie - Calculation = BMR x 1.55
        // Very active(hard exercise / sports 6 - 7 days a week) : Calorie - Calculation = BMR x 1.725
        // If you are extra active(very hard exercise / sports & a physical job) : Calorie - Calculation = BMR x 1.9

        // https://www.omnicalculator.com/health/protein

        let {height,
            weight,
            age,
            gender,
            weeklyActivity} = this.state


        let canCalc = false
        let bmr = 0
        let totalExpenditure = 0

        if (
            height.length>0 && 
            weight.length>0 &&
            age.length>0 &&
            gender.length>0 &&
            weeklyActivity.length>0) {
            canCalc = true
        }

        // For men:
        // BMR = 13.397W + 4.799H - 5.677A + 88.362
        // For women:
        // BMR = 9.247W + 3.098H - 4.330A + 447.593

        if (canCalc) {
            if (gender === 'male') {
                bmr = 13.397 * parseFloat(weight) + 4.799*parseFloat(height) - 5.677*parseInt(age)+88.362
            } else {
                bmr = 9.247 * parseFloat(weight) + 3.098 * parseFloat(height) - 4.330 * parseInt(age) +447.593
            }

            if (weeklyActivity ==='sedentary') {
                totalExpenditure = bmr * 1.2
            } else if (weeklyActivity === 'light') {
                totalExpenditure = bmr * 1.375
            } else if (weeklyActivity === 'moderate') {
                totalExpenditure = bmr * 1.55
            } else if (weeklyActivity === 'very') {
                totalExpenditure = bmr * 1.725
            } else if (weeklyActivity === 'extremely') {
                totalExpenditure = bmr * 1.9
            }

            bmr = Math.round((bmr + Number.EPSILON))
            totalExpenditure = Math.round((totalExpenditure + Number.EPSILON))
        }
        
        this.setState({
            bmr,
            totalExpenditure
        })
        
    }

    render() {
        return (
            <div >
                <h2 className="text-center">Calorie Calculator</h2>
                <p className="text-center">Fill in the form below to find out how many calories you should be consuming each day.</p>
                < div className="mt-md-4 mt-2 form-group row" >
                    <label
                        htmlFor="height"
                        className="col-md-3 text-center text-md-right align-self-center mb-0" >
                        Height</label>
                    <div className="input-group col-md-6 row">
                        <input
                            type='text'
                            value={this.state.height}
                            onChange={this.handleChange}
                            name='height'
                            id="height"
                            className=" form-control text-center col-10"
                        />
                        <div className="input-group-append col-2 px-0">
                            < span className="input-group-text d-block" > cm </span>
                        </div>
                    </div>
                </div>

                < div className="mt-md-4 mt-2 form-group row" >
                    <label
                        htmlFor="weight"
                        className="col-md-3 text-center text-md-right align-self-center mb-0" >
                        Weight</label>
                    <div className="input-group col-md-6 row">
                        <input
                            type='text'
                            value={this.state.weight}
                            onChange={this.handleChange}
                            name='weight'
                            id="weight"
                            className=" form-control text-center col-10"
                        />
                        <div className="input-group-append col-2 px-0">
                            < span className="input-group-text d-block" > kg </span>
                        </div>
                    </div>
                </div>

                < div className="mt-md-4 mt-2 form-group row" >
                    <label
                        htmlFor="age"
                        className="col-md-3 text-center text-md-right align-self-center mb-0" >
                        Age</label>
                    <div className="input-group col-md-6 row">
                        <input
                            type='text'
                            value={this.state.age}
                            onChange={this.handleChange}
                            name='age'
                            id="age"
                            className=" form-control text-center col-10"
                        />
                        <div className="input-group-append col-2 px-0">
                            < span className="input-group-text d-block" > yrs </span>
                        </div>
                    </div>
                </div>

                < div className="mt-md-4 mt-2 form-group row" >
                    <label
                        htmlFor="gender"
                        className="col-md-3 text-center text-md-right align-self-center mb-0" >
                        Gender</label>
                    <div className="col-md-6 d-flex justify-content-around">
                        <div className="custom-control custom-radio">
                            <input
                                className="custom-control-input"
                                type="radio"
                                id="male"
                                value="male"
                                name="gender"
                                onClick={this.handleChange}
                                checked={this.state.gender==='male'} />
                            < label className="custom-control-label"
                                htmlFor="male" >Male</ label>
                        </div>
                        <div className="custom-control custom-radio">
                            <input
                                className="custom-control-input"
                                type="radio"
                                id="female"
                                value="female"
                                name="gender"
                                onClick={this.handleChange}
                                checked={this.state.gender==='female'} />
                            <label className="custom-control-label" 
                                htmlFor="female">Female</label>
                        </div>
                    </div>
                </div>

                < div className="mt-md-4 mt-2 form-group row" >
                    <label
                        htmlFor="age"
                        className="col-md-3 text-center text-md-right align-self-center mb-0" >
                        Activity Level</label>
                    <div className="col-md-6 row">
                        <select 
                            className="custom-select" 
                            aria-label="Default select example"
                            onChange={this.handleChange}
                            value={this.state.weeklyActivity}
                            name='weeklyActivity'>
                            <option 
                                value='sedentary'
                                >Sedentary (little or no exercise)</option>
                            <option
                                value='light'
                            >Lightly active (light exercise/sports 1-3 days/week)</option>
                            <option
                                value='moderate'
                            >Moderately active (moderate exercise/sports 3-5 days/week)</option>
                            <option
                                value='very'
                            >Very active (hard exercise/sports 6-7 days a week)</option>
                            <option
                                value='extremely'
                            >Extremely active (very hard exercise/sports & a physical job)</option>
                        </select>
                    </div>
                </div>
                {this.state.bmr !== 0 && (
                    <div>
                        <hr className="mt-md-5 mt-2" />
                        <h4 className="text-left">Your Results</h4>
                        <div className="form-group row mt-md-3 mt-2">
                            <div className="col-md-6">
                                < div className="form-group row" >
                                    <label
                                        htmlFor="given_name"
                                        className="col-md-6 text-center text-md-right align-self-center mb-0" >
                                        Your Basal Metabolic Rate (BMR)</label>
                                    <div className="input-group col-md-6 row">
                                        <input
                                            type='text'
                                            value={this.state.bmr}
                                            className=" col-8 form-control text-center"
                                            disabled />
                                        <div className="input-group-append col-4 px-0 d-block">
                                            < span className="input-group-text d-block" > kCal </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                < div className="form-group row" >
                                    <label
                                        htmlFor="family_name"
                                        className="col-md-6 text-center text-md-right align-self-center mb-0" >
                                        Your estimated daily total energy expenditure</label>
                                    <div className="input-group col-md-6 row">
                                        <input
                                            type='text'
                                            value={this.state.totalExpenditure}
                                            className=" col-8 form-control text-center"
                                            disabled />
                                        <div className="input-group-append col-4 px-0 d-block">
                                            < span className="input-group-text d-block" > kCal </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
            </div>

        )
    }

}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser
    };
}

export default connect(
    mapStateToProps,
    {
        setAuthorizationToken
    }
)(CalorieCalculator)