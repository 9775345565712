import React from "react";
import { connect } from "react-redux";

const Loader = props => {
  const {status} = props;

  if (status) {
    return (
      <div className="loader-body">
        <div className="loader" />
      </div>
    )
  }

  return (<div />)
}

function mapStateToProps(state) {
  return {
    status: state.loading.status
  };
}

export default connect(mapStateToProps)(Loader);
