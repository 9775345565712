// Configure FontAwesome Library
import {
    library
} from '@fortawesome/fontawesome-svg-core'
import {
    faStar as faStarSolid,
    faStarHalfAlt,
    faSignInAlt,
    faSignOutAlt,
    faCopy as faCopySolid,
    faPaperPlane,
    faShare,
    faUserCheck,
    faToolbox,
    faLock,
    faCheck,
    faUserPlus,
    faFilter,
    faSearch,
    faUserCircle,
    faClipboardList,
    faChevronCircleLeft,
    faChevronCircleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight
} from '@fortawesome/free-solid-svg-icons'
import {
    faStar as faStarRegular,
    faCopy as faCopyRegular,
    faEnvelope,
    faIdBadge as faIdBadgeRegular,
    faCircle,
    faCheckCircle,
    faCalendarAlt,

    
} from '@fortawesome/free-regular-svg-icons';

library.add(
    faStarSolid,
    faStarRegular,
    faStarHalfAlt,
    faSignInAlt,
    faCopySolid,
    faCopyRegular,
    faPaperPlane,
    faShare,
    faUserCheck,
    faEnvelope,
    faSignOutAlt,
    faToolbox,
    faIdBadgeRegular,
    faLock,
    faCheck,
    faCircle,
    faCheckCircle,
    faUserPlus,
    faFilter,
    faSearch,
    faUserCircle,
    faCalendarAlt,
    faClipboardList,
    faChevronCircleLeft,
    faChevronCircleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight
)

export default library