import React from 'react';
import {
    Card, CardHeader, CardBody,
} from 'reactstrap';
import {Link} from "react-router-dom";

const RecipeCard = (props) => {
    return (
        <Link 
            className="col-md-4 col-sm-6 mt-4 text-center"
            to={`/recipes/${props.recipe.id}`}
        >
            <Card>
                <CardHeader tag="h3">{props.recipe.name}</CardHeader>
                <CardBody>
                    <div className="card-text">
                        < div className="mt-2" > 
                            Meal: {props.recipe.meal.map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(', ')} 
                        </ div>
                        <div className="row mt-2">
                            <div className="col-6">Time: {props.recipe.duration} mins</div>
                            <div className="col-6">Serves: {props.recipe.serves}</div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">Cals: {props.recipe.calsPerPerson} kCal</div>
                            <div className="col-6">Protein: {props.recipe.proteinPerPerson} g</div>
                        </div>
                    </div>
                </CardBody>
            </Card>
        </Link>
    );
};

export default RecipeCard;
