import { 
  // apiCall, 
  setTokenHeader 
} from "../../services/api";
import { SET_CURRENT_USER } from "../actionTypes";
// import { addNotification, removeNotification } from "./notifications";
import { setLoading } from "./loading";

export function setCurrentUser(user) {
  return {
    type: SET_CURRENT_USER,
    user
  };
}

export function setAuthorizationToken(token) {
  setTokenHeader(token);
}

export function signIn(user) {
  return dispatch => {
    dispatch(setLoading(true));
    dispatch(setCurrentUser(user));
    dispatch(setLoading(false));
  }
}

// export function signIn(user) {
//   return dispatch => {
//     dispatch(setLoading(true));
//     return apiCall('post', '/auth/signin', user)
//       .then((user) => {
//         dispatch(setCurrentUser(user));
//         dispatch(removeNotification());
//         dispatch(setLoading(false));
//         return {successful: true};
//       })
//       .catch(err => {
//         dispatch(addNotification({message: err.message, notificationType:'danger'}));
//         dispatch(setLoading(false));
//         return {successful: false};
//       });
//   };
// }

export function signOut() {
  return dispatch => {
    localStorage.clear();
    setAuthorizationToken(false);
    dispatch(setCurrentUser({}));
  };
}
