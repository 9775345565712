import React from "react";
import {
    Link,
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import MyProfile from "./MyProfile";
import Tracking from "./Tracking";
import CalorieCalculator from "./CalorieCalculator";

const MealPlanRoutes = props => {
    return(
        <div className="">
            <div className="mt-md-5 mt-2 row d-flex h-100">
                <div className="col-md-3">
                    <Link 
                        to={`/myaccount`}
                        className="btn btn-primary bg-brand-blue-gradient mx-auto w-100 mt-2"
                    >My Profile</Link>
                    <Link
                        to={`/myaccount/tracking`}
                        className="btn btn-primary bg-brand-blue-gradient mx-auto w-100 mt-2"
                    >Weight/BMI Tracking</Link>
                    <Link
                        to={`/myaccount/calculator`}
                        className="btn btn-primary bg-brand-blue-gradient mx-auto w-100 mt-2"
                    >Calorie Calculator</Link>
                </div>
                <div className="col-md-9">
                    <Switch>
                        <Route
                            exact
                            path="/myaccount"
                            render={(props) => (<MyProfile {...props} />)}
                        />
                        <Route
                            exact
                            path="/myaccount/tracking"
                            render={(props) => (<Tracking {...props} />)}
                        />
                        <Route
                            exact
                            path="/myaccount/calculator"
                            render={(props) => (<CalorieCalculator {...props} />)}
                        />
                    </Switch>
                </div>
            </div>
        </div>
    )
}

export default withRouter(MealPlanRoutes);