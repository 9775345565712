import { ADD_NOTIFICATION, REMOVE_NOTIFICATION } from "../actionTypes";

export default (state = { message: null, notificationType: null}, action) => {
  switch (action.type) {
    case ADD_NOTIFICATION:
      return { ...state, message: action.notification.message, notificationType: action.notification.notificationType };
    case REMOVE_NOTIFICATION:
      return { ...state, message: null , notificationType: null};
    default:
      return state;
  }
};
