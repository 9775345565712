import React, { Component } from 'react';
import {
    UncontrolledDropdown,
    // Dropdown, 
    DropdownToggle, DropdownMenu, DropdownItem
} from 'reactstrap';

class SelectMealDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen:false,
            search:'',
            filteredList:[]
        }
        this.search = React.createRef();
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.handleSearchInput = this.handleSearchInput.bind(this);
    }

    toggleDropdown() {
        this.props.toggle()
        this.search.current.focus()
    }

    handleSearchInput = e => {
        let search = e.target.value;
        if (search.length >= 3) {
            let factorsToSearch = [
                'name'
            ]
            // eslint-disable-next-line
            let filteredList = this.props.recipes.filter(function (r) {
                for (let idx in factorsToSearch) {
                    if (r[factorsToSearch[idx]].toLowerCase().includes(search.toLowerCase())) {
                        return r;
                    }
                }
            });

            this.setState({
                search: e.target.value,
                filteredList: filteredList
            });
        } else {
            this.setState({
                search: e.target.value,
                filteredList: []
            });
        }
    }

    render() {
        let options = this.state.filteredList.map(i => (
            <DropdownItem
                key={i.id}
                toggle={false}
                name={i.id}
                onClick={this.props.handleTargetSelect}>
                {i.name}
            </DropdownItem>
        ))

        return(
            <UncontrolledDropdown 
                isOpen={this.props.open} 
                className="mx-auto mx-md-0 mt-2"
            >
                <DropdownToggle 
                    name={this.props.name} 
                    caret 
                    onClick={this.toggleDropdown} 
                    className="bg-brand-dark-blue border-brand-dark-blue btn-block"
                    disabled={this.props.disabled}
                >
                    {this.props.selected ? this.props.selected: 'Please Select'}
                </DropdownToggle>
                <DropdownMenu
                    className="w-md-50"
                    modifiers={{
                        setMaxHeight: {
                            enabled: true,
                            order: 890,
                            fn: (data) => {
                                return {
                                    ...data,
                                    styles: {
                                        ...data.styles,
                                        overflow: 'auto',
                                        maxHeight: 300,
                                    },
                                };
                            },
                        },
                    }}>
                    <DropdownItem header tag='div'>
                        Search
                    </DropdownItem>
                    <DropdownItem toggle={false} tag='div'>
                        <input
                            type='text'
                            value={this.state.search}
                            onChange={this.handleSearchInput}
                            placeholder='Type 3 letters...'
                            ref={this.search}
                        />
                    </DropdownItem>
                    <DropdownItem divider />
                    {options}
                </DropdownMenu>
            </UncontrolledDropdown>
        )
    }
}

export default SelectMealDropdown