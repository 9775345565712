import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input } from 'reactstrap';
import moment from 'moment';

const NewEntryModal = (props) => {
    const {
        open,
        toggle,
        className,
        newEntry,
        handleInput,
        submit,
    } = props;

    return (
        <div>
            <Modal isOpen={open} toggle={toggle} className={className}>
                <ModalHeader toggle={toggle}>Add Progress</ModalHeader>
                <ModalBody>
                    < div className="form-group row d-flex h-100 pr-3" >
                        <label
                            htmlFor="date"
                            className="col-md-3 text-center text-md-left align-self-center mb-0" >
                            Date</label>
                        < Input
                            type="date"
                            name="date"
                            id="date"
                            value={moment(newEntry.date).format('YYYY-MM-DD')}
                            onChange={handleInput}
                            className="col-md-9 form-control text-center"
                            placeholder="date" />
                    </div>
                    < div className="form-group row d-flex h-100 pr-3" >
                        <label
                            htmlFor="weight"
                            className="col-md-3 text-center text-md-left align-self-center mb-0" >
                            Weight</label>
                        < Input
                            type="text"
                            name="weight"
                            id="weight"
                            value={newEntry.weight}
                            onChange={handleInput}
                            className="col-md-9 form-control text-center"
                            placeholder="weight" />
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={submit}>Add</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        </div>
    );
}

export default NewEntryModal