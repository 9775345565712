import React, {Component} from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import Auth from '@aws-amplify/auth';
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import { setAuthorizationToken } from '../store/actions/auth';
import { addNotification } from "../store/actions/notifications";
import {getMealplan} from '../services/mealplan';
import AddToMealPlan from './mealplans/AddToMealPlan';

class Homepage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            mealplan: {
                id: '',
                breakfast: [],
                lunch: [],
                dinner: [],
                snacks: [],
                calsTotal: 0,
                proteinTotal: 0
            },
            today: moment().format('YYYY-MM-DD'),
            addModalOpen: false
        }
        this.toggleAddModal = this.toggleAddModal.bind(this);
        this.onMealAdd = this.onMealAdd.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                //console.log(jwt);
                setAuthorizationToken(jwt);
                this.props.getMealplan(this.state.today)
                    .then(data => {
                        this.setState({
                            mealplan:data
                        })
                    })
            })
            .catch(err => { });
    }

    toggleAddModal = e => {
        this.setState({
            addModalOpen: !this.state.addModalOpen
        })
    }

    onMealAdd = data => {
        let {mealplan} = this.state;

        let food = {
            type: data.type,
            cals: data.cals,
            protein: data.protein,
            name: data.name,
            servings: parseFloat(data.servings),
            unit: data.unit,
            recipeId: data.recipeId,
        }

        mealplan[data.meal].push(food)
        mealplan.calsTotal = mealplan.calsTotal + data.cals
        mealplan.proteinTotal = mealplan.proteinTotal + data.protein
        mealplan.calsTotal = Math.round((mealplan.calsTotal + Number.EPSILON))
        mealplan.proteinTotal = Math.round((mealplan.proteinTotal + Number.EPSILON) * 10) / 10
        this.setState({
            mealplan
        })
    }

    render() {
        let {calsTotal, proteinTotal, breakfast, lunch, dinner, snacks} = this.state.mealplan;

        let mealPlanBreakfast = breakfast.map((b,idx) => (
            <li className="list-group-item" key={`breakfast-${idx}`}>
                {b.type==='recipe' ? (
                    <Link to={`/recipes/${b.recipeId}`}>{b.name} - {b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</Link>
                ): (
                    <div>{b.name} - { b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</div>
                )}
            </li>
        ))

        let mealPlanLunch = lunch.map((b, idx) => (
            <li className="list-group-item" key={`lunch-${idx}`}>
                {b.type==='recipe' ? (
                    <Link to={`/recipes/${b.recipeId}`}>{b.name} - {b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</Link>
                ): (
                    <div>{b.name} - { b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</div>
                )}
            </li>
        ))

        let mealPlanDinner = dinner.map((b, idx) => (
            <li className="list-group-item" key={`dinner-${idx}`}>
                {b.type==='recipe' ? (
                    <Link to={`/recipes/${b.recipeId}`}>{b.name} - {b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</Link>
                ): (
                    <div>{b.name} - { b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</div>
                )}
            </li>
        ))

        let mealPlanSnacks = snacks.map((b, idx) => (
            <li className="list-group-item" key={`snacks-${idx}`}>
                {b.type==='recipe' ? (
                    <Link to={`/recipes/${b.recipeId}`}>{b.name} - {b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</Link>
                ): (
                    <div>{b.name} - { b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</div>
                )}
            </li>
        ))

        
        return(
            <div className="">
                {!this.props.currentUser.isAuthenticated ? (
                    <div>
                        <div className="hero-img">
                            <div className="hero-text">
                                <h1 className="text-center hero-header d-none d-md-block">Welcome to PlanMyMeals.co.uk</h1>
                                <h1 className="text-center hero-header-mob d-block d-md-none">Welcome to PlanMyMeals.co.uk</h1>
                            </div>
                        </div>
                        <div className="mt-2 mt-md-5 text-center">
                            Sign-up or sign-in with the buttons at the top of the page and come join us! 
                        </div>
                    </div>
                ) : (
                    <div>
                        <div className="hero-img">
                            <div className="hero-text">
                                <h1 className="text-center hero-header d-none d-md-block">
                                    Welcome back {this.props.currentUser.user.given_name}!
                                </h1>
                                <h1 className="text-center hero-header-mob d-block d-md-none">
                                    Welcome back {this.props.currentUser.user.given_name}!
                                </h1>
                            </div>
                        </div>
                        <div className="mt-2 mt-md-5 text-center">
                            <h4>What's on the menu today?</h4>
                            {/* Cals/Protein */}
                            <div className="form-group mt-md-5 mt-2 row d-flex h-100 justify-content-around">
                                <div className="col-md-4 d-flex h-100">
                                    < div className="form-group row d-flex h-100 w-100" >
                                        <label
                                            htmlFor="calsTotal"
                                            className="col-4 text-center text-md-left align-self-center mb-0" >
                                            Calories</label>
                                        < div className="input-group col-8" >
                                            <input
                                                type='text'
                                                value={calsTotal || 0}
                                                name='calsTotal'
                                                id="calsTotal"
                                                className="form-control text-center"
                                                disabled={true} />
                                            <div className="input-group-append w-50px">
                                                < span className="input-group-text" > kCal </span>
                                            </div>
                                        </ div>
                                    </div>
                                </div>
                                < div className="col-md-4 d-flex h-100" >
                                    < div className="form-group row d-flex h-100 w-100" >
                                        <label
                                            htmlFor="proteinTotal"
                                            className="col-4 text-center text-md-left align-self-center mb-0" >
                                            Protein</label>
                                        < div className="input-group col-8" >
                                            < input
                                                type='text'
                                                value={proteinTotal || 0}
                                                name='proteinTotal'
                                                id="proteinTotal"
                                                className="form-control text-center"
                                                disabled={true} />
                                                <div className="input-group-append w-50px">
                                                < span className="input-group-text" > g </span>
                                            </div>
                                        </ div>
                                    </div>
                                </div>
                            </div>
                            {/* Meal Plan */}
                            <div id="home-breakfast" className="mt-2 mt-md-4">
                                <h4>Breakfast</h4>
                                <ul className="list-group">
                                    {mealPlanBreakfast}
                                </ul>
                            </div>
                            <div id="home-lunch" className="mt-2 mt-md-4">
                                <h4>Lunch</h4>
                                <ul className="list-group">
                                    {mealPlanLunch}
                                </ul>
                            </div>
                            <div id="home-dinner" className="mt-2 mt-md-4">
                                <h4>Dinner</h4>
                                <ul className="list-group">
                                    {mealPlanDinner}
                                </ul>
                            </div>
                            <div id="home-snacks" className="mt-2 mt-md-4">
                                <h4>Snacks</h4>
                                <ul className="list-group">
                                    {mealPlanSnacks}
                                </ul>
                            </div>
                            <button
                                className="btn btn-primary btn-lg bg-brand-blue-gradient mt-2 mx-auto"
                                onClick={this.toggleAddModal}
                            >Add new meal to today</button>
                        </div>
                        <AddToMealPlan 
                            open={this.state.addModalOpen}
                            toggle={this.toggleAddModal}
                            date={this.state.today}
                            onSubmit={this.onMealAdd}
                        />
                    </div>
                )}
        </div>
        )
    }
};

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser
    };
}

export default connect(mapStateToProps, {
    getMealplan,
    addNotification,
    setAuthorizationToken
})(Homepage);