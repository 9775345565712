import React, { Component } from "react";
import { connect } from "react-redux";
import Auth from '@aws-amplify/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

import { signIn, setAuthorizationToken } from "../../store/actions/auth";


class SignUp extends Component {
    constructor(props) {
        super(props)
        this.state = {
            email: '',
            password: '',
            confirmPassword:'',
            given_name: '',
            family_name:'',
            confirmEmail:'',
            passwordsMatch:true,
            passwordLength:false,
            passwordLowerCase:false,
            passwordNumbers:false,
            passwordUpperCase:false,
            canSignUp:false,
            confirmCode:'',
            formStatus:'new', //new,confirm
        }
        this.handleInput = this.handleInput.bind(this);
        this.signup = this.signup.bind(this);
        this.confirmSignup = this.confirmSignup.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const { attributes } = user;
                this.props.signIn(attributes)
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                this.props.setAuthorizationToken(jwt);
                this.props.history.push('/')
            })
            .catch(err => {});
    }

    validatePassword = () => {
        let passwordLength = false;
        let passwordLowerCase = false;
        let passwordNumbers = false;
        let passwordUpperCase = false;

        let {password} = this.state;

        if (password.match(/[A-Z]/g)) {
            passwordUpperCase = true
        }
        if (password.match(/[a-z]/g)) {
            passwordLowerCase = true
        }
        if (password.match(/[0-9]/g)) {
            passwordNumbers = true
        }
        if(password.length>=8){
            passwordLength=true
        }

        this.setState({
            passwordLength,
            passwordLowerCase,
            passwordUpperCase,
            passwordNumbers,  
        }, () => {
            this.canSubmit();
        })
    }

    canSubmit = () => {
        let {
            passwordLength,
            passwordLowerCase,
            passwordUpperCase,
            passwordNumbers,
            passwordsMatch,
            email,
            given_name,
            family_name
        } = this.state;

        let canSignUp = false;

        if (
            email.length > 0 &&
            given_name.length > 0 &&
            family_name.length > 0 &&
            passwordLength &&
            passwordLowerCase &&
            passwordUpperCase &&
            passwordNumbers &&
            passwordsMatch
        ) {canSignUp = true}

        this.setState({canSignUp})
    }

    handleInput = e => {
        let { 
            confirmEmail, 
            passwordsMatch, 
            password, 
            confirmPassword
        } = this.state;

        if (e.target.name === "email") {
            var splitEmail = e.target.value.split("@")
            var domain = splitEmail[1];
            var name = splitEmail[0];
            confirmEmail =  name.substring(0, 3).concat("*********@").concat(domain)
        }

        if (e.target.name === 'confirmPassword') {
            if(e.target.value === password) {
                passwordsMatch = true
            } else {
                passwordsMatch = false
            }
        }

        if (e.target.name === 'password') {
            if (e.target.value === confirmPassword) {
                passwordsMatch = true
            } else {
                passwordsMatch = false
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            confirmEmail:confirmEmail,
            passwordsMatch: passwordsMatch
        },() => {
            this.validatePassword()
        })

    }

    signup = async e => {
        e.preventDefault()

        let {password, email, given_name, family_name} = this.state;

        try {
            await Auth.signUp({
                username: email,
                password: password,
                attributes: {
                    email: email,
                    given_name: given_name,
                    family_name: family_name
                }
            });
            this.setState({
                formStatus: 'confirm'
            })
        } catch (error) {
            console.log('error signing up:', error);
        }
        
    }

    confirmSignup = async e => {
        e.preventDefault()
        let { email, confirmCode} = this.state;
        try {
            await Auth.confirmSignUp(email, confirmCode);
            this.props.history.push('/auth/signin')
        } catch (error) {
            console.log('error confirming sign up', error);
        }
        
    }

    render() {
        return (
            <div className="text-center mt-md-5 mt-2 auth-form">
                <h1>Sign Up Below</h1>
                <p>Please fill in the form below to create an account!</p>
                <hr />
                {this.state.formStatus === 'new' && (
                    <div className="text-center mt-md-5 mt-2">
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-brand-blue-gradient">
                                        <FontAwesomeIcon icon={['far', 'id-badge']} />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control text-center"
                                    name="given_name"
                                    placeholder="First Name"
                                    required="required"
                                    value={this.state.given_name}
                                    onChange={this.handleInput}
                                />
                                <input
                                    type="text"
                                    className="form-control text-center"
                                    name="family_name"
                                    placeholder="Last Name"
                                    required="required"
                                    value={this.state.family_name}
                                    onChange={this.handleInput}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-brand-blue-gradient">
                                        <FontAwesomeIcon icon={['fas', 'paper-plane']} />
                                    </span>
                                </div>
                                <input 
                                    type="email" 
                                    className="form-control text-center" 
                                    name="email" 
                                    placeholder="Email Address" 
                                    required="required"
                                    value = {this.state.email}
                                    onChange = {this.handleInput}
                                />
			                </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-brand-blue-gradient">
                                        <FontAwesomeIcon icon={['fas', 'lock']} />
                                    </span>
                                </div>
                                <input
                                    type="password"
                                    className="form-control text-center"
                                    name="password"
                                    placeholder="Password"
                                    required="required"
                                    value={this.state.password}
                                    onChange={this.handleInput}
                                />
                                <input
                                    type="password"
                                    className="form-control text-center"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    required="required"
                                    value={this.state.confirmPassword}
                                    onChange={this.handleInput}
                                />
                            </div>
                        </div>
                        <div id="password_val" className="text-left">
                            <p>Password must contain the following:</p>
                            <ul className="fa-ul">
                                <li id="letter">
                                    {this.state.passwordLowerCase ? 
                                        <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true}/>: 
                                        <FontAwesomeIcon icon={['far', 'circle']} listItem={true}/>
                                    } A <b>lowercase</b> letter
                                </li>
                                <li id="capital">
                                    {this.state.passwordUpperCase ? 
                                        <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true}/>: 
                                        <FontAwesomeIcon icon={['far', 'circle']} listItem={true}/>
                                    }A <b>capital (uppercase)</b> letter
                                </li>
                                <li id="number">
                                    {this.state.passwordNumbers ? 
                                        <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true}/>: 
                                        <FontAwesomeIcon icon={['far', 'circle']} listItem={true}/>
                                    }A <b>number</b>
                                </li>
                                <li id="length">
                                    {this.state.passwordLength ? 
                                        <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true}/>: 
                                        <FontAwesomeIcon icon={['far', 'circle']} listItem={true}/>
                                    }Minimum <b>8 characters</b>
                                </li>
                            </ul>
                        </div>
                        <button 
                            className="btn btn-primary btn-lg bg-brand-blue-gradient mx-auto"
                            onClick={this.signup}
                            disabled={!this.state.canSignUp}
                        >
                            Sign Up
                        </button>
                        <hr />
                        <p>Already have an account? <Link to="/auth/signin">Sign in here</Link></p>
                    </div>
                )}
                {this.state.formStatus==="confirm" && (
                    <div className="text-center mt-md-5 mt-2">
                        <p>We've just sent you a code to confirm your email address ({this.state.confirmEmail})</p>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-brand-blue-gradient">
                                        <FontAwesomeIcon icon={['far', 'check-circle']} />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control text-center"
                                    name="confirmCode"
                                    placeholder="Confirmation Code"
                                    required="required"
                                    value={this.state.confirmCode}
                                    onChange={this.handleInput}
                                />
                            </div>
                        </div>
                        <button
                            className="btn btn-primary btn-lg mx-auto"
                            onClick={this.confirmSignup}
                        >
                            Confirm my email address
                        </button>
                    </div>
                )}
            </div>
        )
    }
}

export default connect(
    null,
    {
        signIn,
        setAuthorizationToken
    }
)(SignUp)