import React, { Component } from 'react';
import { connect } from "react-redux";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Input,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink
} from 'reactstrap';
import Auth from '@aws-amplify/auth';
import moment from 'moment';
import { 
    getIngredients, 
    //createNewIngredient,
    getAllRecipes 
} from "../../services/recipe";
import {addToMealPlan} from "../../services/mealplan";
import { setAuthorizationToken } from '../../store/actions/auth';
import { addNotification } from "../../store/actions/notifications";
import SelectMealDropDown from "./SelectMealDropdown";
import SelectIngredientDropdown from "./SelectIngredientDropdown";

class AddToMealPlan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: this.props.date || moment().format('YYYY-MM-DD'),
            meal:'',
            type:'recipe', //recipe,ingredient
            calsPerPerson:0,
            proteinPerPerson:0,
            servings:1,
            cals:0,
            protein:0,
            name:'',
            unit:'Servings',
            recipeId:'',
            recipe:'',
            allRecipes:[],
            filteredRecipes:[],
            allIngredients:[],
            filteredIngredients:[],
            recipeDropdownOpen:false,
            ingredientDropdownOpen:false
        }
        this.getData = this.getData.bind(this);
        this.addToMealPlan = this.addToMealPlan.bind(this);
        this.handleMealSelect = this.handleMealSelect.bind(this);
        this.handleDateInput = this.handleDateInput.bind(this);    
        this.handleRecipeSelect=this.handleRecipeSelect.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                //console.log(jwt);
                setAuthorizationToken(jwt);
                this.getData();
            })
            .catch(err => {});
    }

    getData() {
        this.props.getAllRecipes()
            .then(data => {
                this.setState({
                    allRecipes: data.allRecipes,
                });
            });

        this.props.getIngredients()
            .then(data => {
                data = data.allIngredients.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({
                    allIngredients:data
                })
            })
    }

    handleDateInput = e => {
        this.setState({
            date: moment(e.target.value).format('YYYY-MM-DD')
        })
    }

    handleMealSelect = e => {
        let meal = e.target.value

        let filteredRecipes = this.state.allRecipes.filter(r => {
            return r.meal.includes(meal)
        })

        this.setState({
            meal,
            filteredRecipes
        })
    }

    selectType = e => {
        this.setState({
            type: e.target.name,
            recipeDropdownOpen: false,
            ingredientDropdownOpen: false
        })
    }

    // RECIPES
    toggleRecipeDropdown = e => {
        this.setState({
            recipeDropdownOpen: !this.state.recipeDropdownOpen
        })
    }

    handleRecipeSelect = e => {
        let recipe = this.state.allRecipes.filter(r => {
            return r.id === e.target.name
        })[0]
        let {servings} = this.state
        this.setState({
            recipe,
            calsPerPerson: recipe.calsPerPerson,
            proteinPerPerson: recipe.proteinPerPerson,
            cals: recipe.calsPerPerson * servings,
            protein: recipe.proteinPerPerson * servings,
            name: recipe.name,
            unit: 'Servings',
            recipeId: recipe.id, 
            recipeDropdownOpen:false
        })
    }


    // INGREDIENTS
    toggleIngredientDropdown = e => {
        this.setState({
            ingredientDropdownOpen: !this.state.ingredientDropdownOpen
        })
    }

    handleIngredientSelect = e => {
        let ingredient = this.state.allIngredients.filter(i => {
            return i.id === e.target.name
        })[0]

        let { servings } = this.state

        this.setState({
            calsPerPerson: ingredient.cals,
            proteinPerPerson: ingredient.protein,
            cals: ingredient.cals * servings,
            protein: ingredient.protein * servings,
            name: ingredient.name,
            unit: ingredient.unit,
            recipeId: '',
            recipeDropdownOpen: false,
            ingredientDropdownOpen:false
        })
    }

    // GENERAL
    handleServingInput = e => {
        let {
            calsPerPerson, proteinPerPerson
        } = this.state;
        let servings = parseInt(e.target.value)

        let cals = Math.round(((calsPerPerson * servings) + Number.EPSILON));
        let protein = Math.round(((proteinPerPerson * servings) + Number.EPSILON) * 10) / 10;

        this.setState({
            servings: e.target.value,
            cals,
            protein
        })
    }

    addToMealPlan = e => {
        let date= this.state.date
        
        let body = {
            meal: this.state.meal,
            type: this.state.type,
            cals: this.state.cals,
            protein: this.state.protein,
            name: this.state.name,
            servings:parseFloat(this.state.servings),
            unit: this.state.unit,
            recipeId: this.state.recipeId,
        }

        this.props.addToMealPlan(date, body)
            .then((data) => {
                this.setState({
                    date: this.props.date || moment().format('YYYY-MM-DD'),
                    type: 'recipe', //recipe,ingredient
                    calsPerPerson: 0,
                    proteinPerPerson: 0,
                    servings: 1,
                    cals: 0,
                    protein: 0,
                    name: '',
                    recipeId: '',
                    filteredRecipes: [],
                    recipeDropdownOpen: false
                },() => {
                    if (this.props.onSubmit) {
                        this.props.onSubmit(body)
                    }
                    this.props.toggle()
                })
            })
    }

    render() {
        const {
            open,
            toggle
        } = this.props;

        return(
            <Modal isOpen={open} toggle={toggle} >
                <ModalHeader toggle={toggle}>Add new meal</ModalHeader>
                <ModalBody>
                    {!this.props.date && (
                        < div className="form-group row d-flex h-100 pr-3" >
                        <label
                            htmlFor="meal"
                            className="col-md-3 text-center text-md-left align-self-center mb-0" >
                            Date</label>
                        < Input
                        type = "date"
                        name = "date"
                        id = "exampleDate"
                        value = {this.state.date}
                        onChange={this.handleDateInput}
                        className="col-md-9 form-control text-center"
                        placeholder = "date" />
                    </div>
                    )}
                    < div className="form-group row d-flex h-100 pr-3" >
                        <label
                            htmlFor="meal"
                            className="col-md-3 text-center text-md-left align-self-center mb-0" >
                            Meal</label>
                        <select
                            type='text'
                            value={this.state.meal}
                            onChange={this.handleMealSelect}
                            name='meal'
                            id="meal"
                            className=" col-md-9 form-control text-center" >
                            < option value = ""
                            disabled={true} > Please Select... </ option>
                            < option value="breakfast">Breakfast</option>
                            < option value = "lunch" >Lunch</option>
                            < option value = "dinner" >Dinner</option>
                            < option value = "snacks" > Snacks </option>
                        </select>
                    </div>
                    <Nav tabs className="mt-3 nav-justified">
                        <NavItem>
                            <NavLink
                                className={this.state.type === 'recipe' ? 'active' : ''}
                                onClick={this.selectType}
                                name='recipe'
                                >Recipe</NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={this.state.type==='ingredient' ? 'active': ''}
                                onClick={this.selectType}
                                name='ingredient'
                                >Ingredient</NavLink>
                        </NavItem>
                    </Nav>
                    <TabContent activeTab={this.state.type}>
                        <TabPane tabId="recipe">
                            <SelectMealDropDown 
                                recipes={this.state.filteredRecipes}
                                open={this.state.recipeDropdownOpen}
                                toggle={this.toggleRecipeDropdown}
                                disabled={!this.state.date || !this.state.meal}
                                handleTargetSelect={this.handleRecipeSelect}
                                selected={this.state.name}
                            />
                            <div className="form-group row d-flex h-100 pr-3 mt-3" >
                                <label
                                    htmlFor="servings"
                                    className = "col-md-3 text-center text-md-left align-self-center mb-0" >
                                    Servings </label>
                                <input
                                    type='text'
                                    value={this.state.servings}
                                    onChange={this.handleServingInput}
                                    name='servings'
                                    id='servings'
                                    className="col-md-9 form-control text-center" />
                            </div>
                        </TabPane>
                        <TabPane tabId="ingredient">
                            <SelectIngredientDropdown
                                recipes={this.state.allIngredients}
                                open={this.state.ingredientDropdownOpen}
                                toggle={this.toggleIngredientDropdown}
                                disabled={!this.state.date || !this.state.meal}
                                handleTargetSelect={this.handleIngredientSelect}
                                selected={this.state.type==='ingredient' && this.state.name}
                            />
                            <div className="mt-3 input-group">
                                <input
                                    type='text'
                                    className="form-control"
                                    value={this.state.servings}
                                    onChange={this.handleServingInput}
                                    name='newIngredientQty'
                                />
                                <div className="input-group-append">
                                    < span className="input-group-text" > {this.state.unit} </span>
                                </div>
                            </div>
                        </TabPane>
                    </TabContent>

                    
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.addToMealPlan}>Add</Button>{' '}
                    <Button color="secondary" onClick={toggle}>Cancel</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser
    };
}

export default connect(
    mapStateToProps, {
    getIngredients,
    getAllRecipes,
    addToMealPlan,
    addNotification,
    setAuthorizationToken
})(AddToMealPlan);