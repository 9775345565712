import React, { Component } from 'react'
import {connect} from "react-redux";
import Auth from '@aws-amplify/auth';
import {
    Dropdown, DropdownToggle, DropdownMenu, DropdownItem,
} from 'reactstrap';
import IngredientModal from '../../components/IngredientModal';
import {getIngredients, createNewIngredient, getRecipe, createNewRecipe} from "../../services/recipe"
import {setAuthorizationToken} from '../../store/actions/auth';
import {addNotification} from "../../store/actions/notifications";

class NewRecipe extends Component {
    constructor(props) {
        super(props)
        this.state = {
            allIngredients: [],
            searchDropdownOpen:false,
            ingredientSearch:'',
            filteredIngredients: [],
            newIngredient: {
                name:'',
                quantity:'',
            },
            newStep:'',
            recipe: {
                name: '',
                duration:'',
                serves: '',
                ingredients:[],
                instuctions:[],
                meal:[],
                calsPerPerson:0,
                proteinPerPerson:0
            },
            ingredientModalOpen:false,
            addIngredient:{
                name:'',
                category:'',
                unit:'',
                cals:'',
                protein:''
            },
        }
        this.getData = this.getData.bind(this);
        this.calcNutrition = this.calcNutrition.bind(this);
        this.onRecipeInput = this.onRecipeInput.bind(this);
        this.handleMeal = this.handleMeal.bind(this);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.addRecipeIngredient = this.addRecipeIngredient.bind(this);
        this.toggleIngredientModal = this.toggleIngredientModal.bind(this);
        this.handleIngredientInput = this.handleIngredientInput.bind(this);
        this.updateMethod = this.updateMethod.bind(this);

        this.search = React.createRef();
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                setAuthorizationToken(jwt);

                this.getData();
            })
            .catch(err => {});
    }

    calcNutrition() {
        let {recipe} = this.state;
        let serves, 
        calsPerPerson, 
        proteinPerPerson,
        totalCals,
        totalProtein;

        recipe.serves ? serves = recipe.serves: serves=1;
        totalCals = calsPerPerson = totalProtein = proteinPerPerson = 0

        recipe.ingredients.forEach(ing => {
            totalCals = totalCals + parseFloat(ing.cals)*parseFloat(ing.quantity)
            totalProtein = totalProtein + parseFloat(ing.protein) * parseFloat(ing.quantity)
        });

        calsPerPerson = totalCals/serves;
        proteinPerPerson = totalProtein/serves;

        recipe.calsPerPerson = Math.round((calsPerPerson + Number.EPSILON));
        recipe.proteinPerPerson = Math.round((proteinPerPerson + Number.EPSILON) * 10) / 10;

        this.setState({
            recipe
        })
    }

    getData() {
        this.props.getIngredients()
            .then(data => {
                data = data.allIngredients.sort(function (a, b) {
                    if (a.name < b.name) {
                        return -1;
                    }
                    if (a.name > b.name) {
                        return 1;
                    }
                    return 0;
                });
                this.setState({
                    allIngredients: data,
                    recipe: {
                        name: '',
                        duration: '',
                        serves: '',
                        ingredients: [],
                        instuctions: [],
                        meal: []
                    },
                });
            })
    }

    onRecipeInput = e => {
        let {recipe} = this.state;
        recipe[e.target.name] = e.target.value
        this.setState({recipe})
        this.calcNutrition();
    }

    handleMeal = e => {
        let {
            recipe
        } = this.state;

        let meal = recipe.meal

        if (meal.includes(e.target.value)) {
            meal = meal.filter(el => (el!==e.target.value))
        } else {
            meal.push(e.target.value)
        }

        recipe.meal = meal

        this.setState({
            recipe
        })
    }

    toggleDropdown() {
        this.setState(prevState => ({
            searchDropdownOpen: !prevState.searchDropdownOpen
        }), () => {
            this.search.current.focus()
        });
    }

    handleSearchInput = e => {
        let search = e.target.value;
        if (search.length >= 3) {
            let factorsToSearch = [
                'name'
            ]
            // eslint-disable-next-line
            let filteredIngredients = this.state.allIngredients.filter(function (ing) {
                for (let idx in factorsToSearch) {
                    if (ing[factorsToSearch[idx]].toLowerCase().includes(search.toLowerCase())) {
                        return ing;
                    }
                }
            });

            this.setState({
                [e.target.name]: e.target.value,
                filteredIngredients: filteredIngredients
            });
        } else {
            this.setState({
                [e.target.name]: e.target.value,
                filteredIngredients: []
            });
        }
    }

    handleTargetSelect = e => {
        let ingredient = this.state.allIngredients.filter(function (ing) {
            return ing.id === e.target.name
        })[0];

        let newIngredient = {
            id: ingredient.id,
            name: ingredient.name,
            cals: ingredient.cals,
            protein: ingredient.protein,
            unit: ingredient.unit,
            quantity: ''
        }
        this.setState({ newIngredient })
    }

    handleUpdateQty = e => {
        let ingredient = this.state.newIngredient
        ingredient.quantity = e.target.value
        this.setState({newIngredient: ingredient})
    }

    addRecipeIngredient = e => {
        let recipe = this.state.recipe
        recipe.ingredients.push(this.state.newIngredient)
        this.setState({
            recipe: recipe,
            newIngredient: {
                name: '',
                quantity: '',

            },
            ingredientSearch: '',
            filteredIngredients: [],
        })
        this.calcNutrition();
    }

    toggleIngredientModal = () => {
        this.setState({
            ingredientModalOpen: !this.state.ingredientModalOpen
        })
    }

    handleIngredientInput = e => {
        let {addIngredient} = this.state;
        addIngredient[e.target.name] = e.target.value;
        this.setState({
            addIngredient
        })
    }

    addNewIngredient = e => {
        let {addIngredient, allIngredients} = this.state;
        this.props.createNewIngredient(addIngredient)
            .then(data => {
                allIngredients.push(data);
                this.setState({
                    allIngredients,
                    newIngredient: {
                        id: data.id,
                        name: data.name,
                        cals: data.cals,
                        protein: data.protein,
                        unit: data.unit,
                        quantity: ''
                    },
                    addIngredient: {
                        name: '',
                        category: '',
                        unit: '',
                        cals: '',
                        protein: ''
                    },
                    ingredientModalOpen:false
                })
            })
    }

    updateMethod = e => {
        this.setState({
            newStep: e.target.value
        })
    }

    addStep = e => {
        let recipe = this.state.recipe
        recipe.instuctions.push(this.state.newStep)
        this.setState({
            recipe: recipe,
            newStep: ''
        })
    }

    submitRecipe = e => {
        let recipe = this.state.recipe;
        console.log(recipe)
        this.props.createNewRecipe(recipe)
            .then(() => {
                this.props.history.push('/')
                this.props.addNotification(
                    {
                        'message': 'New recipe added',
                        'notificationType': 'success'
                    }
                )
            })
    }

    render() {
     
        let ingredientOptions = this.state.filteredIngredients.map(i => (
            <DropdownItem
                key={i.id}
                name={i.id}
                onClick={this.handleTargetSelect}>
                {i.name}
            </DropdownItem>
        ))

        let ingredients = this.state.recipe.ingredients.map((i) => {
            return (
                <li className="list-group-item" key={i.id}>{`${i.name} - ${i.quantity} ${i.unit}`}</li>
            )
        });

        let method = this.state.recipe.instuctions.map((i) => (
            <li className="mb-3" key={i}>{i}</li>
        ));

        return(
            <div>
                <IngredientModal 
                    open={this.state.ingredientModalOpen}
                    toggle={this.toggleIngredientModal}
                    ingredient={this.state.addIngredient}
                    handleInput={this.handleIngredientInput}
                    submitForm={this.addNewIngredient}
                />
                <div className="mt-md-5 mt-2 row d-flex h-100 ">
                    <div className="col-md-12">
                        < div className="form-group row d-flex h-100" >
                            <label
                                htmlFor="recipeName"
                                className="col-md-3 text-center text-md-left align-self-center mb-0" >
                                Recipe title</label>
                            <input
                                type='text'
                                value={this.state.recipe.name}
                                onChange={this.onRecipeInput}
                                name='name'
                                id="recipeName"
                                placeholder='e.g. Steak Tartare'
                                className=" col-md-9 form-control" />
                        </div>
                    </div>
                    
                </div>
                <div className="form-group mt-md-5 mt-2 row d-flex h-100 ">
                    <div className="col-md-6 d-flex h-100">
                        < div className="form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="duration"
                                className="col-md-6 text-center text-md-left align-self-center mb-0" >
                                Cooking Time (mins)</label>
                            < div className = "input-group col-md-6" >
                                <input
                                    type='text'
                                    value={this.state.recipe.duration}
                                    onChange={this.onRecipeInput}
                                    name='duration'
                                    id="duration"
                                    placeholder='e.g. 30'
                                    className="form-control text-center" />
                                </ div>
                        </div>
                    </div>
                    < div className = "col-md-6 d-flex h-100" >
                        < div className = "form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="served"
                                className="col-md-6 text-center text-md-left align-self-center mb-0" >
                                Serves</label>
                            < div className = "input-group col-md-6" >
                                <input
                                    type='text'
                                    value={this.state.recipe.serves}
                                    onChange={this.onRecipeInput}
                                    name='serves'
                                    id="served"
                                    placeholder='e.g. 4'
                                    className="form-control text-center" />
                                </ div>
                        </div>
                    </div>
                </div>
                <div className="form-group mt-md-5 mt-2 row d-flex h-100 ">
                    <div className="col-md-6 d-flex h-100">
                        < div className="form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="calsPerPerson"
                                className="col-md-6 text-center text-md-left align-self-center mb-0" >
                                Calories per Person</label>
                            < div className = "input-group col-md-6" >
                                <input
                                    type='text'
                                    value={this.state.recipe.calsPerPerson || 0}
                                    name='calsPerPerson'
                                    id="calsPerPerson"
                                    className="form-control text-center" 
                                    disabled={true}/>
                                <div className="input-group-append">
                                    < span className = "input-group-text" > kCal </span>
                                </div>
                            </ div>
                        </div>
                    </div>
                    < div className = "col-md-6 d-flex h-100" >
                        < div className = "form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="proteinPerPerson"
                                className="col-md-6 text-center text-md-left align-self-center mb-0" >
                                Protein per Person</label>
                            < div className = "input-group col-md-6" > 
                                < input
                                    type='text'
                                    value={this.state.recipe.proteinPerPerson || 0}
                                    name='proteinPerPerson'
                                    id="proteinPerPerson"
                                    className="form-control text-center" 
                                    disabled={true}/>
                                <div className="input-group-append">
                                    < span className = "input-group-text" > g </span>
                                </div>
                            </ div>
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-around">
                    <div className="custom-control custom-checkbox">
                        <input 
                            className="custom-control-input" 
                            type="checkbox" 
                            id="breakfast" 
                            value="breakfast"
                            onChange={this.handleMeal}
                            checked={this.state.recipe.meal.includes('breakfast')} />
                        < label className = "custom-control-label"
                        htmlFor = "breakfast" > Breakfast </ label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input 
                            className="custom-control-input"   
                            type="checkbox" 
                            id="lunch" 
                            value="lunch"
                            onChange={this.handleMeal}
                            checked={this.state.recipe.meal.includes('lunch')} />
                        <label className="custom-control-label" htmlFor="lunch">Lunch</label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input 
                            className="custom-control-input"   
                            type="checkbox" 
                            id="dinner" 
                            value="dinner"
                            onChange={this.handleMeal}
                            checked={this.state.recipe.meal.includes('dinner')} />
                        < label className = "custom-control-label"
                        htmlFor = "dinner" > Dinner </label>
                    </div>
                    <div className="custom-control custom-checkbox">
                        <input 
                            className="custom-control-input"   
                            type="checkbox" 
                            id="snacks" 
                            value="snacks"
                            onChange={this.handleMeal}
                            checked={this.state.recipe.meal.includes('snacks')} />
                        < label className = "custom-control-label"
                        htmlFor = "snacks" > Snacks </label>
                    </div>
                </div>
                <div className="mt-md-5 mt-2">
                    <h4>Ingredients</h4>
                    <ul className="list-group">
                        {ingredients}
                    </ul>
                    <div className="form-group row d-flex h-100 mt-md-5 mt-3">
                        <label
                            htmlFor="newIngredientSearch"
                            className="col-md-3 align-self-center">New Ingredient</label>
                        <div className="col-md-4 col-12 text-center text-md-left" id="newIngredientSearch">
                            <Dropdown isOpen={this.state.searchDropdownOpen} toggle={this.toggleDropdown} className="mx-auto mx-md-0 ">
                                <DropdownToggle caret className="bg-brand-dark-blue border-brand-dark-blue btn-block">
                                    {this.state.newIngredient.id ? this.state.newIngredient.name : 'Please Select'}
                                </DropdownToggle>
                                <DropdownMenu
                                    className="w-md-50"
                                    modifiers={{
                                        setMaxHeight: {
                                            enabled: true,
                                            order: 890,
                                            fn: (data) => {
                                                return {
                                                    ...data,
                                                    styles: {
                                                        ...data.styles,
                                                        overflow: 'auto',
                                                        maxHeight: 300,
                                                    },
                                                };
                                            },
                                        },
                                    }}>
                                    <DropdownItem header tag='div'>
                                        Search
                                    </DropdownItem>
                                    <DropdownItem toggle={false} tag='div'>
                                        <input
                                            type='text'
                                            value={this.state.ingredientSearch}
                                            onChange={this.handleSearchInput}
                                            name='ingredientSearch'
                                            placeholder='Type 3 letters...'
                                            ref={this.search}
                                        />
                                    </DropdownItem>
                                    <DropdownItem divider />
                                    {ingredientOptions.length>0 ? 
                                        ingredientOptions:
                                        <DropdownItem
                                            className = "text-center"
                                            onClick = {this.toggleIngredientModal} href="">Add New Ingredient
                                        </DropdownItem>
                                    }
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                        {this.state.newIngredient.name && 
                            <div className="col-md-5 input-group">
                                <input 
                                    type='number'
                                    className="form-control"
                                    value={this.state.newIngredient.quantity}
                                    onChange={this.handleUpdateQty}
                                    name='newIngredientQty'
                                />
                                <div className="input-group-append">
                                < span className = "input-group-text" > {this.state.newIngredient.unit} </span>
                                <button 
                                    className="btn btn-success" 
                                    onClick={this.addRecipeIngredient}>+</button>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="mt-md-5 mt-2">
                    <h4>Method</h4>
                    <ol className="list-group-numbered">
                        {method}
                    </ol>
                    <div className="row form-group d-flex h-100">
                        <label
                            htmlFor="newStep"
                            className="col-md-3 text-center text-md-left align-self-start mb-0" >
                            Add New Step</label>
                        <div className="col-md-8 px-3">
                            <textarea
                                type='text'
                                value={this.state.newStep}
                                onChange={this.updateMethod}
                                name='newStep'
                                id="newStep"
                                placeholder=''
                                className="form-control" />
                        </div>
                        <div className="col-md-1 px-2">
                            <button
                                className="btn btn-success btn-block align-self-start text-center px-0"
                                onClick={this.addStep}>+</button>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <button 
                        className="btn btn-success"
                        onClick={this.submitRecipe}>Save Recipe</button>
                </div>
            </div>
        )

    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser
    };
}

export default connect(
    mapStateToProps, {
        getIngredients,
        createNewIngredient,
        createNewRecipe,
        getRecipe, 
        addNotification
    })(NewRecipe);