import { apiCall } from "./api";
import { addNotification, removeNotification } from "../store/actions/notifications";
import { setLoading } from "../store/actions/loading";

export function getTracking() {
    return dispatch => {
        dispatch(setLoading(true));
        return apiCall('get', `/tracking`)
            .then((data) => {
                dispatch(removeNotification());
                dispatch(setLoading(false));
                return data;
            })
            .catch(err => {
                dispatch(addNotification({
                    message: err.message,
                    notificationType: 'danger'
                }));
                dispatch(setLoading(false));
            })
    }
}

export function updateTracking(data) {
    return dispatch => {
        dispatch(setLoading(true));
        return apiCall('post', `/tracking`, data)
            .then((data) => {
                dispatch(removeNotification());
                dispatch(setLoading(false));
                return data;
            })
            .catch(err => {
                dispatch(addNotification({
                    message: err.message,
                    notificationType: 'danger'
                }));
                dispatch(setLoading(false));
            })
    }
}