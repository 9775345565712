import { apiCall } from "./api";
import { addNotification, removeNotification } from "../store/actions/notifications";
import { setLoading } from "../store/actions/loading";

export function getIngredients() {
    return dispatch => {
        dispatch(setLoading(true));
        return apiCall('get','/ingredients')
            .then((ingredients) => {
                dispatch(removeNotification());
                dispatch(setLoading(false));
                return ingredients;
            })
            .catch(err => {
                dispatch(addNotification({
                    message: err.message,
                    notificationType: 'danger'
                }));
                dispatch(setLoading(false));
            })
    }
}

export function createNewIngredient(ingredient) {
    return dispatch => {
        dispatch(setLoading(true));
        return apiCall('post','/ingredients', ingredient)
            .then((data) => {
                dispatch(removeNotification());
                dispatch(setLoading(false));
                return data
            }).catch(err => {
                dispatch(addNotification({
                    message: err.message,
                    notificationType: 'danger'
                }));
                dispatch(setLoading(false));
            })
    }
}

export function createNewRecipe(recipe) {
    return dispatch => {
        dispatch(setLoading(true));
        return apiCall('post', '/recipes', recipe)
            .then((data) => {
                dispatch(removeNotification());
                dispatch(setLoading(false));
            }).catch(err => {
                dispatch(addNotification({
                    message: err.message,
                    notificationType: 'danger'
                }));
                dispatch(setLoading(false));
            })
    }
}

export function getAllRecipes() {
    return dispatch => {
        dispatch(setLoading(true));
        return apiCall('get', `/recipes`)
            .then((data) => {
                dispatch(removeNotification());
                dispatch(setLoading(false));
                return data;
            }).catch(err => {
                dispatch(addNotification({
                    message: err.message,
                    notificationType: 'danger'
                }));
                dispatch(setLoading(false));
            })
    }
}

export function getRecipe(id) {
    return dispatch => {
        dispatch(setLoading(true));
        return apiCall('get', `/recipes/${id}`)
            .then((data) => {
                dispatch(removeNotification());
                dispatch(setLoading(false));               
                return data;
            }).catch(err => {
                dispatch(addNotification({
                    message: err.message,
                    notificationType: 'danger'
                }));
                dispatch(setLoading(false));
            })
    }
}