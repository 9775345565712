import React, { Component } from "react";
import { connect } from "react-redux";
import Auth from '@aws-amplify/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { signIn, setAuthorizationToken } from "../../store/actions/auth";
import { getMealplan } from '../../services/mealplan';
import AddToMealPlan from './AddToMealPlan';
import moment from 'moment';

const delay = millis => new Promise((resolve, reject) => {
    setTimeout(_ => resolve(), millis)
});

class MealPlanHome extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: moment().format('YYYY-MM-DD'),
            disabled:true,
            mealplan: {
                id: '',
                breakfast: [],
                lunch: [],
                dinner: [],
                snacks: [],
                calsTotal: 0,
                proteinTotal: 0
            },
            addModalOpen: false 
        }
        this.setDisabledState = this.setDisabledState.bind(this);
        this.changeDay = this.changeDay.bind(this);
        this.toggleAddModal = this.toggleAddModal.bind(this);
        this.onMealAdd = this.onMealAdd.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                //console.log(jwt);
                setAuthorizationToken(jwt);
                this.props.getMealplan(this.state.date)
                    .then(data => {
                        this.setState({
                            mealplan: data
                        })
                    })
            })
            .catch(err => { });
        this.setDisabledState(false)
    }

    setDisabledState = disabledState => {
        this.setState({
            disabled:disabledState
        })
    }

    changeDay = async e => {
        e.preventDefault()
        this.setDisabledState(true)
        let date = this.state.date
        let newDate = date;
        if (e.target.value !== undefined) {
            newDate = e.target.value
        }

        this.props.getMealplan(newDate)
            .then(data => {
                this.setState({
                    date:newDate,
                    mealplan:data
                }, async () => {
                    await delay(500)
                    this.setDisabledState(false)
                })
            })
    }

    toggleAddModal = e => {
        this.setState({
            addModalOpen: !this.state.addModalOpen
        })
    }

    onMealAdd = data => {
        let { mealplan } = this.state;

        let food = {
            type: data.type,
            cals: data.cals,
            protein: data.protein,
            name: data.name,
            servings: parseFloat(data.servings),
            unit: data.unit,
            recipeId: data.recipeId,
        }

        mealplan[data.meal].push(food)
        mealplan.calsTotal = mealplan.calsTotal + data.cals
        mealplan.proteinTotal = mealplan.proteinTotal + data.protein
        mealplan.calsTotal = Math.round((mealplan.calsTotal + Number.EPSILON))
        mealplan.proteinTotal = Math.round((mealplan.proteinTotal + Number.EPSILON) * 10) / 10
        this.setState({
            mealplan
        })
    }

    render() {
        let { calsTotal, proteinTotal, breakfast, lunch, dinner, snacks } = this.state.mealplan;

        let mealPlanBreakfast = breakfast.map((b, idx) => (
            <li className="list-group-item" key={`breakfast-${idx}`}>
                {b.type === 'recipe' ? (
                    <Link to={`/recipes/${b.recipeId}`}>{b.name} - {b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</Link>
                ) : (
                        <div>{b.name} - { b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</div>
                    )}
            </li>
        ))

        let mealPlanLunch = lunch.map((b, idx) => (
            <li className="list-group-item" key={`lunch-${idx}`}>
                {b.type === 'recipe' ? (
                    <Link to={`/recipes/${b.recipeId}`}>{b.name} - {b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</Link>
                ) : (
                        <div>{b.name} - { b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</div>
                    )}
            </li>
        ))

        let mealPlanDinner = dinner.map((b, idx) => (
            <li className="list-group-item" key={`dinner-${idx}`}>
                {b.type === 'recipe' ? (
                    <Link to={`/recipes/${b.recipeId}`}>{b.name} - {b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</Link>
                ) : (
                        <div>{b.name} - { b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</div>
                    )}
            </li>
        ))

        let mealPlanSnacks = snacks.map((b, idx) => (
            <li className="list-group-item" key={`snacks-${idx}`}>
                {b.type === 'recipe' ? (
                    <Link to={`/recipes/${b.recipeId}`}>{b.name} - {b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</Link>
                ) : (
                        <div>{b.name} - { b.servings} {b.unit} (Calories: {b.cals}kCal; Protein: {b.protein}g)</div>
                    )}
            </li>
        ))
        return (
            <div className="text-center mt-md-5 mt-2">
                <h1>Your Meal Plan</h1>
                <hr />
                <div className="form-group mt-md-5 mt-2 row d-flex h-100">
                    <div className="col-3 col-md-2 offset-md-2">
                        <button
                            className="btn btn-primary btn-block bg-brand-blue-gradient"
                            name="yesterday-btn"
                            onClick={this.changeDay}
                            value={moment(this.state.date).subtract(1, 'days').format('YYYY-MM-DD')}
                            disabled={this.state.disabled}
                        >
                            <FontAwesomeIcon icon={['fas', 'chevron-circle-left']} />
                        </button>
                    </div>
                    <div className="col-6 col-md-4 d-flex h-100">
                        < div className="form-group d-flex h-100 w-100" >
                            <input
                                type='text'
                                value={moment(this.state.date).format('Do MMMM YYYY')}
                                name='date'
                                id="date"
                                className="form-control text-center"
                                disabled={true} />
                        </div>
                    </div>
                    <div className="col-3 col-md-2">
                        <button
                            className="btn btn-primary btn-block bg-brand-blue-gradient"
                            name="tomorrow"
                            value={moment(this.state.date).add(1, 'days').format('YYYY-MM-DD')}
                            onClick={this.changeDay}
                            disabled={this.state.disabled}
                        >
                            <FontAwesomeIcon icon={['fas', 'chevron-circle-right']} />
                            {/* {'>'} */}
                        </button>
                    </div>
                </div>
                <hr />
                <div className="form-group mt-md-5 mt-2 row d-flex h-100 justify-content-around">
                    <div className="col-md-4 d-flex h-100">
                        < div className="form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="calsTotal"
                                className="col-4 text-center text-md-left align-self-center mb-0" >
                                Calories</label>
                            < div className="input-group col-8" >
                                <input
                                    type='text'
                                    value={calsTotal || 0}
                                    name='calsTotal'
                                    id="calsTotal"
                                    className="form-control text-center"
                                    disabled={true} />
                                <div className="input-group-append">
                                    < span className="input-group-text" > kCal </span>
                                </div>
                            </ div>
                        </div>
                    </div>
                    < div className="col-md-4 d-flex h-100" >
                        < div className="form-group row d-flex h-100 w-100" >
                            <label
                                htmlFor="proteinTotal"
                                className="col-4 text-center text-md-left align-self-center mb-0" >
                                Protein</label>
                            < div className="input-group col-8" >
                                < input
                                    type='text'
                                    value={proteinTotal || 0}
                                    name='proteinTotal'
                                    id="proteinTotal"
                                    className="form-control text-center"
                                    disabled={true} />
                                <div className="input-group-append">
                                    < span className="input-group-text" > g </span>
                                </div>
                            </ div>
                        </div>
                    </div>
                </div>
                {/* Meal Plan */}
                <div id="home-breakfast" className="mt-2 mt-md-4">
                    <h4>Breakfast</h4>
                    <ul className="list-group">
                        {mealPlanBreakfast}
                    </ul>
                </div>
                <div id="home-lunch" className="mt-2 mt-md-4">
                    <h4>Lunch</h4>
                    <ul className="list-group">
                        {mealPlanLunch}
                    </ul>
                </div>
                <div id="home-dinner" className="mt-2 mt-md-4">
                    <h4>Dinner</h4>
                    <ul className="list-group">
                        {mealPlanDinner}
                    </ul>
                </div>
                <div id="home-snacks" className="mt-2 mt-md-4">
                    <h4>Snacks</h4>
                    <ul className="list-group">
                        {mealPlanSnacks}
                    </ul>
                </div>
                <button
                    className="btn btn-primary btn-lg bg-brand-blue-gradient mt-2 mx-auto"
                    onClick={this.toggleAddModal}
                >Add new meal to today</button>
                <AddToMealPlan
                    open={this.state.addModalOpen}
                    toggle={this.toggleAddModal}
                    date={this.state.date}
                    onSubmit={this.onMealAdd}
                />
            </div>
        )
    }
}

export default connect(
    null,
    {
        signIn,
        setAuthorizationToken,
        getMealplan
    }
)(MealPlanHome)