import React, { Component } from 'react';
import { connect } from "react-redux";
import {withRouter} from "react-router-dom";
import Auth from '@aws-amplify/auth';
import { signIn, setAuthorizationToken } from "../store/actions/auth";

class ProtectedRoute extends Component {
    constructor(props) {
        super(props);
        this.state = {
            display:false
        }
    }
    async componentDidMount(){
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                // console.log(jwt)
                const { attributes } = user;
                setAuthorizationToken(jwt);
                this.props.signIn(attributes);

                this.setState({
                    display: true
                })
            })
            .catch(err => {
                this.props.history.push(`/auth/signin?redirect=${this.props.location.pathname}`)
            })
    }

    render () {
        const C = this.props.render
        return (
            <C />
        )
    }
}

export default connect(
    null, 
    {
        signIn,
        setAuthorizationToken
    }
)(withRouter(ProtectedRoute))