import React, { Component } from 'react'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    // NavbarBrand,
    Nav,
    NavItem,
    // NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    Container
} from 'reactstrap';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setAuthorizationToken, signIn, signOut } from '../store/actions/auth';
import { connect } from "react-redux";
import Auth from '@aws-amplify/auth';
import NavLogo from '../images/logo-white.png';

class PmmNavBar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reduxAuthenticated: false,
            isOpen: false
        };
        this.toggle = this.toggle.bind(this);
        this.logout = this.logout.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                //console.log(jwt)
                const {attributes} = user;
                setAuthorizationToken(jwt);
                this.props.signIn(attributes);
            })
            .catch(err => {});
    }

    toggle() {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    async logout() {
        try {
            await Auth.signOut();
            this.props.signOut();
            this.setState({
                reduxAuthenticated: false
            });
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

    render() {
        const {history} = this.props;
        history.listen(() => {
            this.setState({
                isOpen: false
            })
        });

        return (
            <div>
                <Navbar
                    className="pmm-navbar"
                    dark
                    expand="md"
                >
                    <Container>
                        <Link to="/" className="navbar-brand font-brand-white">
                            <img src={NavLogo} alt="Plan My Meals" style={{ height: 50 }} />
                        </Link>
                        <NavbarToggler onClick={this.toggle} />
                        <Collapse isOpen={this.state.isOpen} navbar>
                            {this.props.currentUser.isAuthenticated === false &&
                                <Nav className="ml-auto font-brand-white" navbar>
                                    <NavItem className="mr-3">
                                        <Link to="/auth/signup" className="btn border-0 nav-link font-brand-white">
                                            <FontAwesomeIcon icon={['fas', 'user-plus']} /> Sign-up
                                        </Link>
                                    </NavItem>
                                    <NavItem>
                                        <Link to="/auth/signin" className="btn border-0 nav-link font-brand-white">
                                            <FontAwesomeIcon icon={['fas', 'sign-in-alt']} /> Login
                                        </Link>
                                    </NavItem>
                                </Nav>
                            }
                            {this.props.currentUser.isAuthenticated === true &&
                                <Nav className="ml-auto font-brand-white" navbar>
                                    <NavItem>
                                        < Link to = '/mealplans'
                                        className = "nav-link font-brand-white mr-5" >
                                            <FontAwesomeIcon icon={['far', 'calendar-alt']} /> Meal Plans
                                        </Link>
                                    </NavItem>
                                    <UncontrolledDropdown nav inNavbar className="mr-5">
                                        < DropdownToggle nav caret className = "font-brand-white" >
                                            <FontAwesomeIcon icon={['fas', 'clipboard-list']} /> Recipes
                                        </DropdownToggle>
                                        <DropdownMenu className="">
                                            <DropdownItem tag='div'>
                                                < Link 
                                                to = '/recipes'
                                                className = "btn border-0 nav-link dropdown-item font-brand-dark-pink" >
                                                    View All Recipes
                                                </Link>
                                            </DropdownItem>
                                            <DropdownItem tag='div'>
                                                < Link 
                                                to = '/recipes/new'
                                                className = "btn border-0 nav-link dropdown-item font-brand-dark-pink" >
                                                    Create New Recipe
                                                </Link>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                    <UncontrolledDropdown nav inNavbar>
                                        < DropdownToggle nav caret className = "font-brand-white" >
                                            <FontAwesomeIcon icon={['fas', 'user-circle']} /> {this.props.currentUser.user.given_name}
                                        </DropdownToggle>
                                        <DropdownMenu className="">
                                            <DropdownItem tag='div'>
                                            < Link 
                                                to = '/myaccount'
                                                className = "btn border-0 nav-link dropdown-item font-brand-dark-pink" >
                                                    <FontAwesomeIcon icon={['far', 'id-badge']} /> My Account
                                                </Link>
                                            </DropdownItem>
                                            <DropdownItem tag='div'>
                                                < Link onClick = {
                                                    this.logout
                                                }
                                                to = ''
                                                className = "btn border-0 nav-link dropdown-item font-brand-dark-pink" >
                                                    <FontAwesomeIcon icon={['fas', 'sign-out-alt']} /> Log out
                                                </Link>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </Nav>
                            }
                        </Collapse>
                    </Container>
                </Navbar>
            </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser
    };
}

export default connect(mapStateToProps,{signIn, signOut})(PmmNavBar)