import { UPDATE_LOADING } from "../actionTypes";

export function setLoading(status) {
  return {
    type: UPDATE_LOADING,
    status
  }
};

export function displayLoader(status) {
  return dispatch => {
    dispatch(setLoading(status));
    // console.log("displayLoader triggered with status: ", status)
  };
}
