import React from "react";
import { Container, Row, Col } from 'reactstrap';

const Footer = () => {
  return(
    < div className = "footer bg-brand-dark-blue font-brand-white" >
        <Container>
            <Row>
                <Col>
                    <div className="footer__copy">
                      Plan My Meals
                    </div>
                </Col>
                <Col className="text-right">
                    <div className="footer__copy">© Kevin Metherell 2020</div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default Footer;
