import React, { Component } from "react";
import { connect } from "react-redux";
import Auth from '@aws-amplify/auth';

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import NewEntryModal from "./NewEntryModal";
import TrackingChart from "./TrackingChart";
import { setAuthorizationToken } from "../../store/actions/auth";
import {getTracking, updateTracking} from "../../services/tracking"
import moment from 'moment';

class Tracking extends Component {
    constructor(props) {
        super(props);
        this.state = {
            target: null,
            progress: [],
            newEntryModalOpen: false,
            newEntry: {
                date: moment().valueOf(),
                weight: ""
            }
        }
        this.updateTracking = this.updateTracking.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.toggleNewEntryModal = this.toggleNewEntryModal.bind(this);
        this.handleNewEntryInput = this.handleNewEntryInput.bind(this);
        this.addNewEntry = this.addNewEntry.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                setAuthorizationToken(jwt);

                this.props.getTracking()
                    .then(data => {
                        this.setState({
                            target: data.target,
                            progress: data.progress.sort((a, b) => (new Date(a.date) - new Date(b.date)))
                        })
                    })
            })
            .catch(err => { });
    }

    updateTracking = e => {
        let {
            target, 
            progress
        } = this.state

        let data = {
            target,
            progress
        }

        this.props.updateTracking(data)
    }

    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    toggleNewEntryModal = e => {
        let {newEntryModalOpen} = this.state
        this.setState({
            newEntryModalOpen: !newEntryModalOpen
        });
    }

    handleNewEntryInput = e => {
        let {newEntry} = this.state;

        newEntry[e.target.name] = e.target.value

        if (e.target.name === 'date') {
            newEntry['date'] = moment(newEntry['date']).valueOf()
        }

        this.setState({
            newEntry
        })
    }

    addNewEntry = e => {
        let { target,
            progress,
            newEntry} = this.state;

        progress.push(newEntry)
        
        // TODO - Find record with matching date and replace
        
        progress = progress.sort((a, b) => (new Date(a.date) - new Date(b.date)))

        this.setState({
            target: target,
            progress: progress,
            newEntryModalOpen: false,
            newEntry: {
                date: moment().valueOf(),
                weight: ""
            }
        }, ()=> {
            this.updateTracking()
        })

    }

    render() {
        let latestEntry = "No Entries Tracked"
        

        if (this.state.progress.length > 0) {
            latestEntry = this.state.progress.slice(-1)[0]['weight'];
        }        

        return (
            <div className="text-center">
                <NewEntryModal 
                    open = {this.state.newEntryModalOpen}
                    toggle={this.toggleNewEntryModal}
                    className=""
                    newEntry = {this.state.newEntry}
                    handleInput={this.handleNewEntryInput}
                    submit={this.addNewEntry}
                />
                <h2>Weight/BMI Tracking</h2>
                <div className="form-group row  mt-3">
                    <div className="col-md-6">
                        < div className="form-group row" >
                            <label
                                htmlFor="target"
                                className="col-md-6 text-center text-md-right align-self-center mb-0" >
                                Target Weight</label>
                            <div className="input-group col-md-6 row">
                                <input
                                    type='text'
                                    value={this.state.target!==null ? this.state.target : 'No Target Set'}
                                    onChange={this.handleChange}
                                    name='target'
                                    id="target"
                                    className=" col-8  form-control text-center"/>
                                <div className="input-group-append col-4 px-0">
                                    < span className="input-group-text d-block" > kg </span>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <button
                            className="btn btn-primary bg-brand-blue-gradient mx-auto"
                            onClick={this.updateTracking}
                        >Save new target</button>
                    </div>
                </div>

                <div className="form-group row  mt-3">
                    <div className="col-md-6">
                        < div className="form-group row" >
                            <label
                                htmlFor="target"
                                className="col-md-6 text-center text-md-right align-self-center mb-0" >
                                Latest Entry</label>
                            <div className="input-group col-md-6 row">
                                <input
                                    type='text'
                                    value={latestEntry}
                                    name='target'
                                    id="target"
                                    className=" col-8  form-control text-center" 
                                    disabled/>
                                <div className="input-group-append col-4 px-0">
                                    < span className="input-group-text d-block" > kg </span>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="col-md-4">
                        <button
                            className="btn btn-primary bg-brand-blue-gradient mx-auto"
                            onClick={this.toggleNewEntryModal}
                        >Add New Entry</button>
                    </div>
                </div>

                <div className="mt-md-5">
                    <TrackingChart 
                        data={this.state.progress} 
                        target = {this.state.target}
                    />
                </div>
            </div>
            
        )
    }

}

function mapStateToProps(state) {
    return {
        currentUser: state.currentUser
    };
}

export default connect(
    mapStateToProps,
    {
        setAuthorizationToken,
        getTracking, 
        updateTracking
    }
)(Tracking)