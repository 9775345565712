import { apiCall } from "./api";
import { addNotification, removeNotification } from "../store/actions/notifications";
import { setLoading } from "../store/actions/loading";

export function getMealplan(date) {
    return dispatch => {
        dispatch(setLoading(true));
        return apiCall('get', `/mealplans/${date}`)
            .then((data) => {
                dispatch(removeNotification());
                dispatch(setLoading(false));
                return data;
            })
            .catch(err => {
                dispatch(addNotification({
                    message: err.message,
                    notificationType: 'danger'
                }));
                dispatch(setLoading(false));
            })
    }
}

export function addToMealPlan(date, body) {
    return dispatch => {
        dispatch(setLoading(true));
        return apiCall('post', `/mealplans/${date}`, body)
            .then((data) => {
                dispatch(removeNotification());
                dispatch(setLoading(false));
                return data;
            })
            .catch(err => {
                dispatch(addNotification({
                    message: err.message,
                    notificationType: 'danger'
                }));
                dispatch(setLoading(false));
            })
    }
}