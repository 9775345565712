import React from 'react';
import {Provider} from "react-redux";
import {BrowserRouter as Router,Route, Switch} from "react-router-dom";
import {configureStore} from "../store";
import Amplify from '@aws-amplify/core';
// import Auth from '@aws-amplify/auth';

// Route Management
import ProtectedRoute from "./ProtectedRoute"
import AuthRoutes from "./auth/AuthRoutes";
import MealPlanRoutes from "./mealplans/MealPlanRoutes";
import RecipeRoutes from './recipes/RecipeRoutes';
import MyAccountRoutes from './myaccount/MyAccountRoutes';

// General Page components
import {Container} from 'reactstrap';
import Navbar from "./Navbar";
import Notification from "./Notifications";
import Footer from "../components/Footer";
import Homepage from "./Homepage";
import Loader from "./Loader";

// FontAwesome
require("../services/fontAwesome")

// Configure Redux store
const store = configureStore();

Amplify.configure({
  Auth: {
    region: process.env.REACT_APP_AWS_REGION,
    userPoolId: process.env.REACT_APP_COGNITO_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_COGNITO_WEBCLIENTID,
    mandatorySignIn: false,
    authenticationFlowType: 'USER_SRP_AUTH',
  }
});

function App() {
  return ( 
    <Provider store = {store} >
      <Router >
        <div className = "App" > 
          {/*Navbar*/ } 
          <Route 
            path = "/"
            render = {(props) => < Navbar {...props}/>}  
          /> 
          <Notification />
          {/*Core Page Content*/ } 
          <Container > 
            <Switch>
            {/* Index Route*/ } 
              <Route
                exact
                path = "/"
                render = {() => < Homepage />}
              />
              <Route
                path="/auth"
                render = {(props) => <AuthRoutes {...props} />}
              />
              {/* Recipe Routes */ }
              <ProtectedRoute
                path="/recipes"
                render={(props) => <RecipeRoutes {...props} />}
              /> 
              <ProtectedRoute
                path="/mealplans"
                render={(props) => <MealPlanRoutes {...props} />}
              /> 
              <ProtectedRoute
                path="/myaccount"
                render={(props) => <MyAccountRoutes {...props} />}
              />
            </Switch>
          </Container> 
          {/*Page Footer*/ } 
          <Footer /> 
          {/*Loading Spinner*/ } 
          <Loader />
        </div>  
      </Router>  
    </Provider>
  )
}

export default App;