import React from "react";
import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import NewRecipe from "./NewRecipe";
import AllRecipes from "./AllRecipes";
import ViewRecipe from "./ViewRecipe";

const RecipeRoutes = props => {
  return(
    <Switch>
        {/* All Recipes */}
            <Route
            exact
            path="/recipes"
            render = {() => < AllRecipes />}
            />
        {/* New Recipe */}
            <Route
                exact
                path="/recipes/new"
                render = {(props) => < NewRecipe {...props}/>}
            />
        {/* View Recipe */}
            <Route
                exact
                path = "/recipes/:recipeId"
                render = {(props) => < ViewRecipe {...props}/>}
            />
    </Switch>
  )
}

export default withRouter(RecipeRoutes);