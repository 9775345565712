import { combineReducers } from "redux";
import notifications from "./notifications";
import loading from "./loading";
import currentUser from "./currentUser";

const rootReducer = combineReducers({
  notifications,
  loading,
  currentUser,
});

export default rootReducer;
