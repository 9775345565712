import React, { Component } from "react";
import { connect } from "react-redux";
import Auth from '@aws-amplify/auth';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Link } from "react-router-dom";
import { signIn, setAuthorizationToken } from "../../store/actions/auth";

class PasswordReset extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email:'',
            emailAnon:'',
            password: '',
            confirmPassword: '',
            passwordsMatch: true,
            passwordLength: false,
            passwordLowerCase: false,
            passwordNumbers: false,
            passwordUpperCase: false,
            canSignUp: false,
            resetCode: '',
            formStatus: 'new', //new,confirm
        }
        this.handleInput = this.handleInput.bind(this);
        this.requestResetCode = this.requestResetCode.bind(this);
        this.resetPassword = this.resetPassword.bind(this);
        this.validatePassword = this.validatePassword.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
    }

    async componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(async user => {
                const { attributes } = user;
                this.props.signIn(attributes)
                const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
                this.props.setAuthorizationToken(jwt);
                this.props.history.push('/')
            })
            .catch(err => { });
    }

    validatePassword = () => {
        let passwordLength = false;
        let passwordLowerCase = false;
        let passwordNumbers = false;
        let passwordUpperCase = false;

        let { password } = this.state;

        if (password.match(/[A-Z]/g)) {
            passwordUpperCase = true
        }
        if (password.match(/[a-z]/g)) {
            passwordLowerCase = true
        }
        if (password.match(/[0-9]/g)) {
            passwordNumbers = true
        }
        if (password.length >= 8) {
            passwordLength = true
        }

        this.setState({
            passwordLength,
            passwordLowerCase,
            passwordUpperCase,
            passwordNumbers,
        }, () => {
            this.canSubmit();
        })
    }

    canSubmit = () => {
        let {
            passwordLength,
            passwordLowerCase,
            passwordUpperCase,
            passwordNumbers,
            passwordsMatch,
            email,
            resetCode
        } = this.state;

        let canSignUp = false;

        if (
            email.length > 0 &&
            resetCode.length > 0 &&
            passwordLength &&
            passwordLowerCase &&
            passwordUpperCase &&
            passwordNumbers &&
            passwordsMatch
        ) { canSignUp = true }

        this.setState({ canSignUp })
    }

    handleInput = e => {
        let { 
            emailAnon, 
            passwordsMatch, 
            password, 
            confirmPassword
        } = this.state;

        if (e.target.name === "email") {
            var splitEmail = e.target.value.split("@")
            var domain = splitEmail[1];
            var name = splitEmail[0];
            emailAnon =  name.substring(0, 3).concat("*********@").concat(domain)
        }

        if (e.target.name === 'confirmPassword') {
            if(e.target.value === password) {
                passwordsMatch = true
            } else {
                passwordsMatch = false
            }
        }

        if (e.target.name === 'password') {
            if (e.target.value === confirmPassword) {
                passwordsMatch = true
            } else {
                passwordsMatch = false
            }
        }

        this.setState({
            [e.target.name]: e.target.value,
            emailAnon:emailAnon,
            passwordsMatch: passwordsMatch
        },() => {
            this.validatePassword()
        })
    }

    requestResetCode = e => {
        let {email} = this.state
        Auth.forgotPassword(email)
            .then(data => {
                console.log(data)
                this.setState({
                    formStatus: 'confirm'
                })
            })
            .catch(err => console.log(err));
        
    }

    resetPassword = e => {
        let{email, resetCode, password} = this.state;
        Auth.forgotPasswordSubmit(email, resetCode, password)
            .then(data => {
                this.props.history.push('/auth/signin')
            })
            .catch(err => {
                console.log('Error:', err)
            });
    }

    render() {
        return (
            <div className="text-center mt-md-5 mt-2 auth-form">
                <h1>Password Reset</h1>
                <p>Please fill in the form below to reset your password.</p>
                <hr />
                {this.state.formStatus === 'new' && (
                    <div className="text-center mt-md-5 mt-2">
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-brand-blue-gradient">
                                        <FontAwesomeIcon icon={['fas', 'paper-plane']} />
                                    </span>
                                </div>
                                <input
                                    type="email"
                                    className="form-control text-center"
                                    name="email"
                                    placeholder="Email Address"
                                    required="required"
                                    value={this.state.email}
                                    onChange={this.handleInput}
                                />
                            </div>
                        </div>
                        
                        <button
                            className="btn btn-primary btn-lg bg-brand-blue-gradient mx-auto"
                            onClick={this.requestResetCode}
                            disabled={!this.state.email.length}
                        >
                            Send me a password reset code
                        </button>
                    </div>
                )}
                {this.state.formStatus === "confirm" && (
                    <div className="text-center mt-md-5 mt-2">
                        <p>We've just sent you a code so you can reset your password to your email address ({this.state.confirmEmail})</p>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-brand-blue-gradient">
                                        <FontAwesomeIcon icon={['far', 'check-circle']} />
                                    </span>
                                </div>
                                <input
                                    type="text"
                                    className="form-control text-center"
                                    name="resetCode"
                                    placeholder="Confirmation Code"
                                    required="required"
                                    autoComplete="off"
                                    value={this.state.resetCode}
                                    onChange={this.handleInput}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text bg-brand-blue-gradient">
                                        <FontAwesomeIcon icon={['fas', 'lock']} />
                                    </span>
                                </div>
                                <input
                                    type="password"
                                    className="form-control text-center"
                                    name="password"
                                    placeholder="Password"
                                    required="required"
                                    autoComplete="off"
                                    value={this.state.password}
                                    onChange={this.handleInput}
                                />
                                <input
                                    type="password"
                                    className="form-control text-center"
                                    name="confirmPassword"
                                    placeholder="Confirm Password"
                                    required="required"
                                    value={this.state.confirmPassword}
                                    onChange={this.handleInput}
                                />
                            </div>
                        </div>
                        <div id="password_val" className="text-left">
                            <p>Password must contain the following:</p>
                            <ul className="fa-ul">
                                <li id="letter">
                                    {this.state.passwordLowerCase ?
                                        <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true} /> :
                                        <FontAwesomeIcon icon={['far', 'circle']} listItem={true} />
                                    } A <b>lowercase</b> letter
                                </li>
                                <li id="capital">
                                    {this.state.passwordUpperCase ?
                                        <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true} /> :
                                        <FontAwesomeIcon icon={['far', 'circle']} listItem={true} />
                                    }A <b>capital (uppercase)</b> letter
                                </li>
                                <li id="number">
                                    {this.state.passwordNumbers ?
                                        <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true} /> :
                                        <FontAwesomeIcon icon={['far', 'circle']} listItem={true} />
                                    }A <b>number</b>
                                </li>
                                <li id="length">
                                    {this.state.passwordLength ?
                                        <FontAwesomeIcon icon={['far', 'check-circle']} listItem={true} /> :
                                        <FontAwesomeIcon icon={['far', 'circle']} listItem={true} />
                                    }Minimum <b>8 characters</b>
                                </li>
                            </ul>
                        </div>
                        <button
                            className="btn btn-primary btn-lg mx-auto bg-brand-blue-gradient "
                            onClick={this.resetPassword}
                            disabled={!this.state.canSignUp}
                        >
                            Reset my password
                        </button>
                    </div>
                )}
            </div>
        )
    }

}

export default connect(
    null,
    {
        signIn,
        setAuthorizationToken
    }
)(PasswordReset)