import React, { Component }from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Alert, Container } from 'reactstrap';
import { removeNotification } from '../store/actions/notifications'

class Notification extends Component {
  // constructor(props) {
  //   super(props)
  // }
  async componentDidUpdate() {
    if (this.props.notifications.notificationType) {
      await new Promise(resolve => setTimeout(resolve, 5000));
      this.props.removeNotification();
    }
  }

  render() {
    const { message, notificationType } = this.props.notifications;

    if (notificationType == null) return null;

    return (
      <Container>
        <Alert
          color={notificationType}
          className="mt-3 mx-5"
        >{message}
        </Alert>
      </Container>
    );
  }
};

function mapStateToProps(state) {
  return {
    notifications: state.notifications
  };
}

export default withRouter(
  connect(mapStateToProps, { removeNotification })(Notification)
);
