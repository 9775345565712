import React from "react";
import {
    Switch,
    Route,
    withRouter
} from "react-router-dom";
import MealPlanHome from "./MealPlanHome";

const MealPlanRoutes = props => {
    return(
        <Switch>
            <Route
                exact
                path="/mealplans"
                render={(props) => (<MealPlanHome {...props}/>)}
            />
        </Switch>
    )
}

export default withRouter(MealPlanRoutes);